/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Checkbox,
  DropZone,
  BlockStack,
  Text,
  Button,
  Thumbnail,
  Box,
  Select,
  Card,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { MultiComboOption, MultiSelectCombobox } from "../MultiSelectCombobox";
import DatePickerField from "../DatePickerField";
import TranslateLink from "../TranslateLink";
import { PhoneNumberField } from "../PhoneNumberField/PhoneNumberField";
import { CountryProvider } from "@/hooks/useCountries";
import SingleSelectCombobox from "../SingleSelectCombobox/SingleSelectCombobox";
import { PatientJsonBadge } from "@/clinic/pages/patient/types";
/* same props interface */
interface ComboOption {
  label: string;
  value: string;
}

interface PropertyTypeRendererProps {
  id:
    | "textInput"
    | "textArea"
    | "number"
    | "checkbox"
    | "phoneNumber"
    | "email"
    | "date"
    | "combobox"
    | "combeBoxMultiple"
    | "dropZone"
    | "jsonBadge";
  label?: string;
  value?: string | number | boolean | string[] | PatientJsonBadge;
  onChange: (newValue: any) => void;
  disabled?: boolean;
  helpText?: string;
  placeholder?: string;
  comboOptions?: ComboOption[];
  error?: string;
  isRequired?: boolean;
  file?: File;
  onDrop?: (_dropFiles: File[], acceptedFiles: File[]) => void;
  onRemoveFile?: (file: File) => void;
}

const _PropertyTypeRenderer: React.FC<PropertyTypeRendererProps> = ({
  isRequired,
  id,
  label,
  value,
  onChange,
  disabled = false,
  helpText,
  placeholder,
  comboOptions = [],
  error,
  file,
  onDrop,
  onRemoveFile,
}) => {
  const { t } = useTranslation();
  // Add local state for text fields
  const [localValue, setLocalValue] = useState(
    value == null ? "" : String(value)
  );

  // Update local value when prop value changes
  useEffect(() => {
    setLocalValue(value == null ? "" : String(value));
  }, [value]);

  // Common label/placeholder
  const stringValue = value == null ? "" : String(value);
  const computedLabel = label ?? t("PropertyTypeRenderer.label");

  const computedPlaceholder =
    placeholder ?? t("PropertyTypeRenderer.placeholder");

  // Checkbox
  const boolValue = value === "true" ? true : false;

  // Multi-Select
  const [multiSelected, setMultiSelected] = useState<string[]>([]);
  useEffect(() => {
    if (id !== "combeBoxMultiple") return;
    if (Array.isArray(value)) {
      setMultiSelected(value);
    } else if (typeof value === "string" && value.length > 0) {
      const splitted = value.split(",").map((v) => v.trim());
      setMultiSelected(splitted);
    } else {
      setMultiSelected([]);
    }
  }, [id, value]);

  const handleMultiSelectChange = useCallback(
    (newSelected: string[]) => {
      setMultiSelected(newSelected);
      onChange(newSelected);
    },
    [onChange]
  );

  // Phone number example: parse string as JSON object
  if (id === "phoneNumber") {
    return (
      <CountryProvider>
        <PhoneNumberField
          value={stringValue}
          onChange={(newE164) => onChange(newE164)}
          phoneLabel={computedLabel}
          phoneHelpText={helpText}
          phoneError={error}
          disabled={disabled}
          isRequired={isRequired}
        />
      </CountryProvider>
    );
  }

  // Switch for other field types
  const jsonBadgeValue = (value as PatientJsonBadge) || {};

  switch (id) {
    case "textInput":
    case "textArea":
    case "email":
    case "number":
      return (
        <div
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onChange(localValue);
            }
          }}
        >
          <TextField
            label={computedLabel}
            value={localValue}
            onChange={(newValue) => setLocalValue(newValue)}
            onBlur={() => onChange(localValue)}
            type={id === "number" ? "number" : "text"}
            multiline={id === "textArea" ? 4 : undefined}
            disabled={disabled}
            helpText={helpText}
            placeholder={computedPlaceholder}
            autoComplete="off"
            error={error}
            requiredIndicator={isRequired}
          />
        </div>
      );

    case "checkbox":
      return (
        <Checkbox
          label={computedLabel}
          checked={boolValue}
          onChange={(value) => onChange(`${value}`)}
          disabled={disabled}
          helpText={helpText}
          error={error}
        />
      );
    case "jsonBadge":
      return (
        <BlockStack gap="100">
          <Text variant="bodyMd" as="h6">
            {computedLabel}
          </Text>
          <Card>
            <form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onChange(jsonBadgeValue);
                }
              }}
            >
              <BlockStack gap="400">
                <div>
                  <TextField
                    label={t("PropertyTypeRenderer.badge.label")}
                    value={jsonBadgeValue?.label ?? ""}
                    onChange={(newValue) =>
                      onChange({ ...jsonBadgeValue, label: newValue })
                    }
                    autoComplete="off"
                  />
                </div>
                <div>
                  <TextField
                    label={t("PropertyTypeRenderer.badge.tooltip")}
                    value={jsonBadgeValue?.tooltip ?? ""}
                    onChange={(newValue) =>
                      onChange({ ...jsonBadgeValue, tooltip: newValue })
                    }
                    autoComplete="off"
                  />
                </div>
                <div>
                  <Select
                    label={t("PropertyTypeRenderer.badge.tone")}
                    options={[
                      {
                        label: t("PropertyTypeRenderer.tones.positive"),
                        value: "positive",
                      },
                      {
                        label: t("PropertyTypeRenderer.tones.negative"),
                        value: "negative",
                      },
                      {
                        label: t("PropertyTypeRenderer.tones.warning"),
                        value: "warning",
                      },
                      {
                        label: t("PropertyTypeRenderer.tones.info"),
                        value: "info",
                      },
                    ]}
                    value={jsonBadgeValue?.tone ?? ""}
                    onChange={(newValue) =>
                      onChange({ ...jsonBadgeValue, tone: newValue })
                    }
                  />
                </div>
                <div>
                  <Select
                    label={t("PropertyTypeRenderer.badge.progress")}
                    options={[
                      {
                        label: t("PropertyTypeRenderer.progress.incomplete"),
                        value: "incomplete",
                      },
                      {
                        label: t(
                          "PropertyTypeRenderer.progress.partiallyComplete"
                        ),
                        value: "partiallyComplete",
                      },
                      {
                        label: t("PropertyTypeRenderer.progress.complete"),
                        value: "complete",
                      },
                    ]}
                    value={jsonBadgeValue?.progress ?? ""}
                    onChange={(newValue) =>
                      onChange({ ...jsonBadgeValue, progress: newValue })
                    }
                  />
                </div>
              </BlockStack>
            </form>
          </Card>
        </BlockStack>
      );

    case "date":
      return (
        <DatePickerField
          value={stringValue}
          onChange={onChange}
          label={computedLabel || t("PropertyTypeRenderer.dateLabel")}
          disabled={disabled}
          isRequired={isRequired}
        />
      );

    case "combobox":
      return (
        <SingleSelectCombobox
          label={computedLabel}
          options={comboOptions}
          onChange={onChange}
          value={stringValue}
          disabled={disabled}
          helpText={helpText}
          error={error}
        />
      );

    case "combeBoxMultiple":
      return (
        <BlockStack gap="200">
          <Text variant="headingSm" as="h4">
            {computedLabel}
          </Text>
          <MultiSelectCombobox
            options={comboOptions as MultiComboOption[]}
            selected={multiSelected}
            onChange={handleMultiSelectChange}
            label={computedLabel}
            placeholder={computedPlaceholder}
          />
          {error && (
            <Text tone="critical" as="p">
              {error}
            </Text>
          )}
        </BlockStack>
      );

    case "dropZone":
      return (
        <BlockStack gap="200">
          <Text as="h3" variant="bodyMd">
            {computedLabel}
          </Text>
          {file ? (
            <BlockStack key={file.name}>
              <Thumbnail
                source={URL.createObjectURL(file)}
                alt={file.name}
                size="large"
              />
              <Text as="p">{file.name}</Text>
              <Box>
                <Button
                  variant="tertiary"
                  onClick={() => onRemoveFile && onRemoveFile(file)}
                  tone="critical"
                >
                  {t("common.remove")}
                </Button>
              </Box>
            </BlockStack>
          ) : (
            <DropZone
              onDrop={onDrop}
              disabled={disabled}
              allowMultiple={false}
              type="image"
              accept="image/*"
            >
              <DropZone.FileUpload
                actionTitle={
                  computedPlaceholder || t("PropertyTypeRenderer.uploadFiles")
                }
              />
            </DropZone>
          )}
          {error && (
            <Text tone="critical" as="p">
              {error}
            </Text>
          )}
          {helpText && <TranslateLink text={helpText} />}
        </BlockStack>
      );

    default:
      return (
        <div
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onChange(localValue);
            }
          }}
        >
          <TextField
            autoComplete="off"
            label={computedLabel || t("PropertyTypeRenderer.unknownTypeLabel")}
            value={localValue}
            onChange={(newValue) => setLocalValue(newValue)}
            onBlur={() => onChange(localValue)}
            disabled={disabled}
            helpText={helpText}
            error={error}
          />
        </div>
      );
  }
};

export const PropertyTypeRenderer = _PropertyTypeRenderer;
