/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Checkbox,
  DropZone,
  BlockStack,
  Text,
  Button,
  Thumbnail,
  Box,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { MultiComboOption, MultiSelectCombobox } from "../MultiSelectCombobox";
import DatePickerField from "../DatePickerField";
import TranslateLink from "../TranslateLink";
import { PhoneNumberField } from "../PhoneNumberField/PhoneNumberField";
import { CountryProvider } from "@/hooks/useCountries";
import NumberRangeField from "./NumberRangeField/NumberRangeField";
import SearchableChoiceList from "./SearchableChoiceList/SearchableChoiceList";

/* same props interface */
interface ComboOption {
  label: string;
  value: string;
}

interface FilterComponentRendererProps {
  id:
    | "textInput"
    | "textArea"
    | "number"
    | "checkbox"
    | "phoneNumber"
    | "email"
    | "date"
    | "combobox"
    | "combeBoxMultiple"
    | "dropZone";
  label?: string;
  value?: string | number | boolean | string[];
  onChange: (newValue: any) => void;
  disabled?: boolean;
  helpText?: string;
  placeholder?: string;
  comboOptions?: ComboOption[];
  error?: string;
  isRequired?: boolean;
  file?: File;
  onDrop?: (_dropFiles: File[], acceptedFiles: File[]) => void;
  onRemoveFile?: (file: File) => void;
}

const _FilterComponentRenderer: React.FC<FilterComponentRendererProps> = ({
  isRequired,
  id,
  label,
  value,
  onChange,
  disabled = false,
  helpText,
  placeholder,
  comboOptions = [],
  error,
  file,
  onDrop,
  onRemoveFile,
}) => {
  const { t } = useTranslation();
  // Add local state for text fields
  const [localValue, setLocalValue] = useState(
    value == null ? "" : String(value)
  );

  // Update local value when prop value changes
  useEffect(() => {
    setLocalValue(value == null ? "" : String(value));
  }, [value]);

  // Common label/placeholder
  const stringValue = value == null ? "" : String(value);
  const computedLabel = label ?? t("FilterComponentRenderer.label", "Label");

  const computedPlaceholder =
    placeholder ?? t("FilterComponentRenderer.placeholder", "Enter value");

  // Checkbox
  const boolValue = value === "true" ? true : false;

  // Multi-Select
  const [multiSelected, setMultiSelected] = useState<string[]>([]);
  useEffect(() => {
    if (id !== "combeBoxMultiple") return;
    if (Array.isArray(value)) {
      setMultiSelected(value);
    } else if (typeof value === "string" && value.length > 0) {
      const splitted = value.split(",").map((v) => v.trim());
      setMultiSelected(splitted);
    } else {
      setMultiSelected([]);
    }
  }, [id, value]);

  const handleMultiSelectChange = useCallback(
    (newSelected: string[]) => {
      setMultiSelected(newSelected);
      onChange(newSelected);
    },
    [onChange]
  );

  // Phone number example: parse string as JSON object
  if (id === "phoneNumber") {
    return (
      <CountryProvider>
        <PhoneNumberField
          value={stringValue}
          onChange={(newE164) => onChange(newE164)}
          phoneLabel={computedLabel}
          phoneHelpText={helpText}
          phoneError={error}
          disabled={disabled}
          isRequired={isRequired}
        />
      </CountryProvider>
    );
  }

  // Switch for other field types
  switch (id) {
    case "number":
      return (
        <div
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onChange(localValue);
            }
          }}
        >
          <NumberRangeField
            label={computedLabel}
            value={stringValue}
            onChange={(newValue) => setLocalValue(newValue)}
            disabled={disabled}
            helpText={helpText}
            placeholder={computedPlaceholder}
            error={error}
            requiredIndicator={isRequired}
          />
        </div>
      );
    case "textInput":
    case "textArea":
    case "email":
      return (
        <div
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onChange(localValue);
            }
          }}
        >
          <TextField
            label={computedLabel}
            value={localValue}
            onChange={(newValue) => setLocalValue(newValue)}
            onBlur={() => onChange(localValue)}
            type={id === "email" ? "email" : "text"}
            multiline={id === "textArea" ? 4 : undefined}
            disabled={disabled}
            helpText={helpText}
            placeholder={computedPlaceholder}
            autoComplete="off"
            error={error}
            requiredIndicator={isRequired}
          />
        </div>
      );

    case "checkbox":
      return (
        <Checkbox
          label={computedLabel}
          checked={boolValue}
          onChange={(value) => onChange(`${value}`)}
          disabled={disabled}
          helpText={error ?? helpText}
        />
      );

    case "date":
      return (
        <DatePickerField
          value={stringValue}
          onChange={onChange}
          label={
            computedLabel ||
            t("FilterComponentRenderer.dateLabel", "Select date")
          }
          disabled={disabled}
          isRequired={isRequired}
        />
      );

    case "combobox":
      return (
        <SearchableChoiceList
          label={computedLabel}
          options={comboOptions}
          onChange={onChange}
          value={stringValue}
          disabled={disabled}
          helpText={helpText}
          error={error}
        />
      );

    case "combeBoxMultiple":
      return (
        <BlockStack gap="200">
          <Text variant="headingSm" as="h4">
            {computedLabel}
          </Text>
          <MultiSelectCombobox
            options={comboOptions as MultiComboOption[]}
            selected={multiSelected}
            onChange={handleMultiSelectChange}
            label={computedLabel}
            placeholder={computedPlaceholder}
          />
          {error && (
            <Text tone="critical" as="p">
              {error}
            </Text>
          )}
        </BlockStack>
      );

    case "dropZone":
      return (
        <BlockStack gap="200">
          <Text as="h3" variant="bodyMd">
            {computedLabel}
          </Text>
          {file ? (
            <BlockStack key={file.name}>
              <Thumbnail
                source={URL.createObjectURL(file)}
                alt={file.name}
                size="large"
              />
              <Text as="p">{file.name}</Text>
              <Box>
                <Button
                  variant="tertiary"
                  onClick={() => onRemoveFile && onRemoveFile(file)}
                  tone="critical"
                >
                  {t("common.remove")}
                </Button>
              </Box>
            </BlockStack>
          ) : (
            <DropZone
              onDrop={onDrop}
              disabled={disabled}
              allowMultiple={false}
              type="image"
              accept="image/*"
            >
              <DropZone.FileUpload
                actionTitle={
                  computedPlaceholder ||
                  t("FilterComponentRenderer.uploadFiles", "Upload files")
                }
              />
            </DropZone>
          )}
          {error && (
            <Text tone="critical" as="p">
              {error}
            </Text>
          )}
          {helpText && <TranslateLink text={helpText} />}
        </BlockStack>
      );

    default:
      return (
        <div
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onChange(localValue);
            }
          }}
        >
          <TextField
            autoComplete="off"
            label={
              computedLabel ||
              t("FilterComponentRenderer.unknownTypeLabel", "Unknown Type")
            }
            value={localValue}
            onChange={(newValue) => setLocalValue(newValue)}
            onBlur={() => onChange(localValue)}
            disabled={disabled}
            helpText={helpText}
            error={error}
          />
        </div>
      );
  }
};

/**
 * A shallow comparison of relevant props
 * to avoid re-renders if not needed.
 */
function propsAreEqual(
  prevProps: FilterComponentRendererProps,
  nextProps: FilterComponentRendererProps
): boolean {
  // Check a few key props that might change
  return (
    prevProps.value === nextProps.value &&
    prevProps.error === nextProps.error &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.label === nextProps.label &&
    prevProps.helpText === nextProps.helpText &&
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.id === nextProps.id &&
    // if comboOptions never changes in your scenario, you can compare .length
    prevProps.comboOptions === nextProps.comboOptions &&
    prevProps.file === nextProps.file &&
    prevProps.onDrop === nextProps.onDrop &&
    prevProps.onRemoveFile === nextProps.onRemoveFile
  );
}

/**
 * Export a memoized version
 */
export const FilterComponentRenderer = React.memo(
  _FilterComponentRenderer,
  propsAreEqual
);
