import { Modal, TextField } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { Tag } from "../../types";
import { useEffect, useState } from "react";

interface RenameTagModalProps {
  open: boolean;
  onClose: () => void;
  tag: Tag | null;
  onRename: (tagData: Omit<Tag, "id">) => void;
  isSubmitting: boolean;
}

export function RenameTagModal({
  open,
  onClose,
  tag,
  onRename,
  isSubmitting,
}: RenameTagModalProps) {
  const { t } = useTranslation();
  const [name, setName] = useState(tag?.name || "");

  useEffect(() => {
    setName(tag?.name || "");
  }, [tag]);

  const handleSubmit = () => {
    if (tag && name.trim()) {
      const tagData: Omit<Tag, "id"> = {
        name: name.trim(),
        color: tag.color,
        icon: tag.icon || undefined,
        isDynamic: tag.isDynamic || false,
      };

      // Only include conditions if isDynamic is true and conditions exist
      if (tag.isDynamic && tag.conditions) {
        tagData.conditions = Array.isArray(tag.conditions)
          ? tag.conditions
          : [tag.conditions];
      }

      onRename(tagData);
    }
  };

  const handleClose = () => {
    if (!isSubmitting) {
      setName(tag?.name || "");
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={t("tags.renameTag")}
      primaryAction={{
        content: t("common.update"),
        onAction: handleSubmit,
        loading: isSubmitting,
        disabled: isSubmitting || !name.trim(),
      }}
      secondaryActions={[
        {
          content: t("common.cancel"),
          onAction: handleClose,
          disabled: isSubmitting,
        },
      ]}
    >
      <Modal.Section>
        <TextField
          label={t("tags.name")}
          value={name}
          onChange={setName}
          autoComplete="off"
          requiredIndicator
        />
      </Modal.Section>
    </Modal>
  );
}
