/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Modal,
  BlockStack,
  SkeletonBodyText,
  Box,
  Banner,
  Button,
  Popover,
  ActionList,
  InlineStack,
  Checkbox,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import { Patient, Property } from "../types";
import useApiRequest from "@/hooks/useApiRequest";
import { PropertyTypeRenderer } from "@/common/PropertyTypeRenderer/PropertyTypeRenderer";
import TranslateLink from "@/common/TranslateLink";
import {
  AdjustIcon,
  HideIcon,
  MenuHorizontalIcon,
  ViewIcon,
} from "@shopify/polaris-icons";
import { CLINIC_ROUTES } from "@/utils/Constants";
import { showToast } from "@/reducers/toastSlice";
import { useDispatch } from "react-redux";

interface AddPatientModalProps {
  open: boolean;
  onClose: () => void;
}

const AddPatientModal: React.FC<AddPatientModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { GET, POST } = useApiRequest();
  const queryClient = useQueryClient();
  const params = useParams();
  const clinicId = params.clinicId;
  const [showPickedFields, setShowPickedFields] = useState(true);
  const navigate = useNavigate();
  const [activePopover, setActivePopover] = useState(false);
  const [createAnother, setCreateAnother] = useState(false);
  const dispatch = useDispatch();
  const [files, setFiles] = useState<
    { propertyName: string; file: File | null }[]
  >([]);

  const { data: propertiesData = [], isLoading: isPropertiesLoading } =
    useQuery<Property[]>({
      queryKey: ["patient-schema-properties"],
      queryFn: () =>
        GET(`/api/healthcare-companies/${clinicId}/patients/schema/properties`),
    });

  // Initialize form values with defaultValue or empty
  const initialFormValues = useMemo(() => {
    const init: Record<string, any> = {};
    propertiesData
      .filter((p) => (showPickedFields ? p.isInCreateForm : true))
      .forEach((p) => {
        if (p.defaultValue !== undefined) {
          init[p.name] = p.defaultValue;
        } else {
          init[p.name] = undefined;
        }
      });
    return init;
  }, [propertiesData, showPickedFields]);

  const [formValues, setFormValues] =
    useState<Record<string, any>>(initialFormValues);

  useEffect(() => {
    setFormValues(initialFormValues);
  }, [initialFormValues]);

  // Errors: { [propName]: "Error message" }
  const [errors, setErrors] = useState<Record<string, string>>({});

  const fileUploadMutation = useMutation({
    mutationFn: async ({
      patientId,
      file,
      propertyName,
    }: {
      patientId: string;
      file: File;
      propertyName: string;
    }) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("data", JSON.stringify({ id: propertyName }));

      return POST(
        `/api/healthcare-companies/${clinicId}/patients/${patientId}/upload`,
        formData
      );
    },
    onSuccess: () => {
      handlePatientCreated();
    },
  });

  const handleDropZoneDrop = useCallback(
    (propertyName: string, acceptedFiles: File[]) => {
      setFiles((prevFiles) => {
        const updatedFiles = prevFiles.filter(
          (f) => f.propertyName !== propertyName
        );
        updatedFiles.push({ propertyName, file: acceptedFiles[0] });
        return updatedFiles;
      });
    },
    [setFiles]
  );

  const handleRemoveFile = useCallback((propertyName: string) => {
    setFiles((prevFiles) =>
      prevFiles.filter((f) => f.propertyName !== propertyName)
    );
  }, []);

  const handlePatientCreated = () => {
    queryClient.invalidateQueries({ queryKey: ["patients"] });
    dispatch(
      showToast({
        message: t("addPatientModal.addPatientSuccessMsg"),
        type: "success",
      })
    );
    if (createAnother) {
      setFormValues(initialFormValues);
    } else {
      onClose();
    }
  };

  const handleChange = useCallback(
    (propName: string, newValue: any) => {
      setFormValues((prev) => ({ ...prev, [propName]: newValue }));
      setErrors((prev) => ({ ...prev, [propName]: "" }));
    },
    [setFormValues, setErrors]
  );

  // Create mutation
  const createPatientMutation = useMutation({
    mutationFn: async (payload: Record<string, any>) => {
      return POST<Patient>(
        `/api/healthcare-companies/${clinicId}/patients`,
        payload
      );
    },
    onSuccess: (data) => {
      if (files.length > 0) {
        files.forEach(({ propertyName, file }) => {
          if (data.id && file) {
            fileUploadMutation.mutate({
              patientId: data.id,
              file,
              propertyName,
            });
          }
        });
      } else {
        handlePatientCreated();
      }
    },
    onError: (err: any) => {
      const error = err?.response?.data?.error;
      console.error(error);
      if (error.inline && error.display) {
        setErrors((prev) => ({
          ...prev,
          [error.inline]: error.display,
        }));
      }
    },
  });

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    propertiesData.forEach((p: Property) => {
      const value = formValues[p.name];

      // Check if the field is required
      if (p.isRequired) {
        if (
          value === "" ||
          value === null ||
          value === undefined ||
          (Array.isArray(value) && value.length === 0)
        ) {
          newErrors[p.name] = t("addPatientModal.validation.requiredField");
        }
      }

      // Check if the field has a regex pattern and validate against it
      if (p.regexValueValidation && value) {
        const trimmedValue = value.trim();
        const regexPattern = p.regexValueValidation.replace(/^\/|\/$/g, "");
        const regex = new RegExp(regexPattern);
        if (!regex.test(trimmedValue)) {
          newErrors[p.name] = t("addPatientModal.validation.notAValidValue", {
            regexValueValidation: p.regexValueValidation,
          });
        }
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    } else {
      return true;
    }
  };

  const handleSave = () => {
    if (validateForm()) {
      createPatientMutation.mutate(formValues);
    }
  };

  const handleNavigate = () => {
    navigate(`../${CLINIC_ROUTES.PATIENTS_DATA_MANAGEMENT}`);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("addPatientModal.title")}
      primaryAction={{
        content: t("common.save"),
        onAction: handleSave,
        loading: createPatientMutation.isPending,
      }}
      size="large"
      secondaryActions={[
        {
          content: t("common.cancel"),
          onAction: onClose,
        },
      ]}
      footer={
        <InlineStack gap="400" align="center">
          <Checkbox
            label={t("addPatientModal.addAnotherPatient")}
            checked={createAnother}
            onChange={() => setCreateAnother(!createAnother)}
          />
        </InlineStack>
      }
    >
      {isPropertiesLoading ? (
        <Box padding={"400"}>
          <SkeletonBodyText />
        </Box>
      ) : (
        <Box paddingBlock={"400"} paddingInline={"400"}>
          <BlockStack gap="400">
            <Banner tone="info">
              <TranslateLink
                text={t("addPatientModal.customizeFieldsMessage")}
              />
            </Banner>

            <InlineStack gap="400" align="space-between">
              <p>{t("addPatientModal.requiredFieldsNote")}</p>
              <div>
                <Popover
                  active={activePopover}
                  activator={
                    <div>
                      <Button
                        onClick={() => setActivePopover(!activePopover)}
                        variant="plain"
                        icon={MenuHorizontalIcon}
                      ></Button>
                    </div>
                  }
                  onClose={() => setActivePopover(false)}
                  preferredAlignment="right"
                >
                  <ActionList
                    items={[
                      {
                        content: t("addPatientModal.configureField"),
                        onAction: handleNavigate,
                        icon: AdjustIcon,
                      },
                      {
                        content: showPickedFields
                          ? t("addPatientModal.showAllFields")
                          : t("addPatientModal.showPickedFields"),
                        onAction: () => setShowPickedFields(!showPickedFields),
                        icon: showPickedFields ? ViewIcon : HideIcon,
                      },
                    ]}
                  />
                </Popover>
              </div>
            </InlineStack>

            <BlockStack gap="400">
              {propertiesData
                .filter((p) => (showPickedFields ? p.isInCreateForm : true))
                .sort(
                  (a, b) => (a.displayOrder ?? 999) - (b.displayOrder ?? 999)
                )
                .map((prop: Property) => {
                  const fileObj = files.find(
                    (f) => f.propertyName === prop.name
                  );
                  return (
                    <PropertyTypeRenderer
                      isRequired={prop.isRequired}
                      key={prop.name}
                      id={prop.renderer?.id ?? "textInput"}
                      label={prop.label}
                      value={formValues[prop.name]}
                      onChange={(val) => handleChange(prop.name, val)}
                      disabled={false}
                      helpText={prop.description}
                      placeholder=""
                      comboOptions={prop.options ?? []}
                      error={errors[prop.name]}
                      file={fileObj?.file ?? undefined}
                      onDrop={(acceptedFiles) =>
                        handleDropZoneDrop(prop.name, acceptedFiles)
                      }
                      onRemoveFile={() => handleRemoveFile(prop.name)}
                    />
                  );
                })}
            </BlockStack>
          </BlockStack>
        </Box>
      )}
    </Modal>
  );
};

export default AddPatientModal;
