import ReactMarkdown from "react-markdown";
import { Link, Text } from "@shopify/polaris";

interface TranslateLinkProps {
  text: string;
  onClick?(): void;
  disabled?: boolean;
  external?: boolean;
}

export default function TranslateLink({
  text,
  onClick,
  disabled = false,
  external = false,
}: TranslateLinkProps) {
  return (
    <ReactMarkdown
      children={text}
      components={{
        a: ({ href, children }) => (
          <Link
            url={href}
            onClick={onClick}
            removeUnderline={!external}
            external={href?.startsWith("https://")}
          >
            {children}
          </Link>
        ),
        p: ({ children }) => (
          <Text as="p" tone={disabled ? "disabled" : "subdued"}>
            {children}
          </Text>
        ),
      }}
    />
  );
}
