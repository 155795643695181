import { Modal, TextField, ColorPicker, Checkbox } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { Tag } from "../../types";
import { useEffect, useState } from "react";

interface CreateTagModalProps {
  open: boolean;
  onClose: () => void;
  onAddTag: (tag: Omit<Tag, "id">) => void;
  isSubmitting: boolean;
  editTag?: Tag | null;
}

export function CreateTagModal({
  open,
  onClose,
  onAddTag,
  isSubmitting,
  editTag,
}: CreateTagModalProps) {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const [color, setColor] = useState({
    hue: 120,
    brightness: 1,
    saturation: 1,
  });
  const [isDynamic, setIsDynamic] = useState(false);
  const [conditions, setConditions] = useState("");
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  useEffect(() => {
    if (editTag) {
      setName(editTag.name);
      setIcon(editTag.icon || "");
      setIsDynamic(editTag.isDynamic || false);
      setConditions(editTag.conditions || "");
      // Convert color string to HSL object
      if (editTag.color) {
        const hslMatch = editTag.color.match(
          /hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/
        );
        if (hslMatch) {
          setColor({
            hue: parseInt(hslMatch[1]),
            saturation: parseInt(hslMatch[2]) / 100,
            brightness: parseInt(hslMatch[3]) / 100,
          });
        }
      }
    }
  }, [editTag]);

  const handleSubmit = () => {
    setHasAttemptedSubmit(true);

    if (name.trim() && (!isDynamic || conditions.trim())) {
      onAddTag({
        name: name.trim(),
        color: `hsl(${color.hue}, ${color.saturation * 100}%, ${
          color.brightness * 100
        }%)`,
        icon,
        isDynamic,
        conditions: isDynamic ? conditions.trim() : undefined,
      });
    }
  };

  const handleClose = () => {
    if (!isSubmitting) {
      setName("");
      setIcon("");
      setColor({ hue: 120, brightness: 1, saturation: 1 });
      setIsDynamic(false);
      setConditions("");
      setHasAttemptedSubmit(false);
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={editTag ? t("tags.editTag") : t("tags.createNewTag")}
      primaryAction={{
        content: editTag ? t("common.update") : t("common.create"),
        onAction: handleSubmit,
        loading: isSubmitting,
        disabled:
          isSubmitting ||
          (hasAttemptedSubmit &&
            (!name.trim() || (isDynamic && !conditions.trim()))),
      }}
      secondaryActions={[
        {
          content: t("common.cancel"),
          onAction: handleClose,
          disabled: isSubmitting,
        },
      ]}
    >
      <Modal.Section>
        <div style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
          <TextField
            label={t("tags.name")}
            value={name}
            onChange={setName}
            autoComplete="off"
            error={
              hasAttemptedSubmit && !name.trim()
                ? t("tags.nameRequired")
                : undefined
            }
            requiredIndicator
          />

          <TextField
            label={t("tags.icon")}
            value={icon}
            onChange={setIcon}
            autoComplete="off"
          />

          <div>
            <div style={{ marginBottom: "4px" }}>{t("tags.color")}</div>
            <ColorPicker onChange={setColor} color={color} />
          </div>

          <Checkbox
            label={t("tags.isDynamic")}
            checked={isDynamic}
            onChange={setIsDynamic}
          />

          {isDynamic && (
            <TextField
              label={t("tags.conditions")}
              value={conditions}
              onChange={setConditions}
              autoComplete="off"
              multiline={4}
              error={
                hasAttemptedSubmit && isDynamic && !conditions.trim()
                  ? t("tags.conditionsRequired")
                  : undefined
              }
              helpText={t("tags.conditionsHelpText")}
              requiredIndicator
            />
          )}
        </div>
      </Modal.Section>
    </Modal>
  );
}
