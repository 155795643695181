import React, { useState, useCallback } from "react";
import {
  TextField,
  Button,
  BlockStack,
  Text,
  InlineStack,
  Box,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import "./styles.scss";
interface NumberRangeFieldProps {
  value: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
  label?: string;
  helpText?: string;
  placeholder?: string;
  error?: string;
  requiredIndicator?: boolean;
}

const NumberRangeField: React.FC<NumberRangeFieldProps> = ({
  value,
  onChange,
  disabled = false,
  label,
  helpText,
  placeholder,
  error,
  requiredIndicator,
}) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<"exact" | "range">(
    value.includes("|") ? "range" : "exact"
  );
  const [exactValue, setExactValue] = useState(value);
  const [rangeValues, setRangeValues] = useState(() => {
    const [min, max] = value.split("|");
    return { min: Number(min) || undefined, max: Number(max) || undefined };
  });

  const handleExactChange = useCallback(
    (newValue: string) => {
      setExactValue(newValue);
      onChange(newValue);
    },
    [onChange]
  );

  const handleRangeChange = useCallback(
    (field: "min" | "max", newValue: string) => {
      const newRangeValues = { ...rangeValues, [field]: newValue };
      setRangeValues(newRangeValues);
      onChange(`${newRangeValues.min}|${newRangeValues.max}`);
    },
    [rangeValues, onChange]
  );

  return (
    <BlockStack gap="500">
      {label && <Text as="span">{label}</Text>}
      <BlockStack gap="200">
        <InlineStack gap="400" align="space-between">
          <Box width="45%">
            <Button
              pressed={mode === "exact"}
              onClick={() => setMode("exact")}
              fullWidth
              variant={mode === "exact" ? "tertiary" : "monochromePlain"}
            >
              {t("PropertyTypeRenderer.exactNumber")}
            </Button>
          </Box>
          <Box width="45%">
            <Button
              pressed={mode === "range"}
              onClick={() => setMode("range")}
              fullWidth
              variant={mode === "range" ? "tertiary" : "monochromePlain"}
            >
              {t("PropertyTypeRenderer.range")}
            </Button>
          </Box>
        </InlineStack>
        {mode === "exact" ? (
          <TextField
            autoFocus={true}
            label={""}
            type="number"
            value={exactValue}
            onChange={handleExactChange}
            disabled={disabled}
            helpText={helpText}
            placeholder={placeholder}
            autoComplete="off"
            error={error}
            requiredIndicator={requiredIndicator}
          />
        ) : (
          <BlockStack gap="100">
            <InlineStack gap="200" wrap={false} blockAlign="baseline">
              <Box width="45%">
                <TextField
                  type="number"
                  autoFocus={true}
                  value={rangeValues.min?.toString()}
                  onChange={(value) => handleRangeChange("min", value)}
                  disabled={disabled}
                  placeholder={placeholder}
                  autoComplete={"off"}
                  error={
                    error ||
                    (rangeValues.max !== undefined &&
                      rangeValues.min !== undefined &&
                      rangeValues.min > rangeValues.max)
                  }
                  requiredIndicator={requiredIndicator}
                  label={""}
                  max={rangeValues.max}
                />
              </Box>
              <Box width="10%">
                <Text as="span" variant="bodyMd" alignment="center">
                  to
                </Text>
              </Box>
              <Box width="45%">
                <TextField
                  label={""}
                  type="number"
                  value={rangeValues.max?.toString()}
                  onChange={(value) => handleRangeChange("max", value)}
                  disabled={disabled}
                  placeholder={placeholder}
                  autoComplete={"off"}
                  error={
                    error ||
                    (rangeValues.max !== undefined &&
                      rangeValues.min !== undefined &&
                      rangeValues.min > rangeValues.max)
                  }
                  requiredIndicator={requiredIndicator}
                  min={rangeValues.min}
                />
              </Box>
            </InlineStack>
            <Text as="span" variant="bodyMd" tone="subdued">
              {helpText}
            </Text>
          </BlockStack>
        )}
      </BlockStack>
    </BlockStack>
  );
};

export default NumberRangeField;
