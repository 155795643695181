import AppPageWrapper from "@/common/AppPageWrapper";
import { CLINIC_ROUTES, ROOTS } from "@/utils/Constants";
import { formatDate } from "@/utils/utils";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../styles.scss";
import {
  HideIcon,
  ImageIcon,
  ChatIcon,
  StatusActiveIcon,
  LinkIcon,
  PrintIcon,
  EditIcon,
  PlusIcon,
  ViewIcon,
  NoteIcon,
  ClipboardIcon,
  ChevronRightIcon,
  CalendarTimeIcon,
  EmailIcon,
  PlusCircleIcon,
  ContractFilledIcon,
} from "@shopify/polaris-icons";
import {
  ActionList,
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Filters,
  Icon,
  InlineStack,
  Layout,
  Page,
  Popover,
  ResourceItem,
  ResourceList,
  Tabs,
  Text,
  Link,
  Divider,
  SkeletonThumbnail,
} from "@shopify/polaris";
import RenderDate from "@/common/RenderDate";
import TagsList from "./Tags/TagsList";
import usePatientDetails from "@/hooks/usePatientDetails";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";

function PatientDetails() {
  const { t, i18n } = useTranslation();

  const { clinicId, patientId } = useParams<{
    patientId: string;
    clinicId: string;
  }>();

  const { patient, isLoading } = usePatientDetails(
    patientId ?? "",
    clinicId ?? ""
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const queryClient = useQueryClient();
  const { PUT } = useApiRequest();

  const mutation = useMutation({
    mutationFn: (selectedTagIds: string[]) =>
      PUT(`/api/healthcare-companies/${clinicId}/patients/${patientId}/tags`, {
        tagIds: selectedTagIds,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["patientDetails", patientId],
      });
    },
    onError: (error) => {
      console.error("Error updating tags:", error);
    },
  });

  const handleTagsUpdate = useCallback(
    (selectedTagIds: string[]) => {
      mutation.mutate(selectedTagIds);
    },
    [mutation]
  );

  const tabs = [
    { id: "all", content: t("patients.tabs.all"), panelID: "all-content" },
    {
      id: "treatments",
      content: t("patients.tabs.treatments"),
      panelID: "treatments-content",
    },
    {
      id: "images",
      content: t("patients.tabs.images"),
      panelID: "images-content",
    },
    {
      id: "documents",
      content: t("patients.tabs.documents"),
      panelID: "documents-content",
    },
    { id: "sms", content: t("patients.tabs.sms"), panelID: "sms-content" },
    {
      id: "tasks",
      content: t("patients.tabs.tasks"),
      panelID: "tasks-content",
    },
  ];

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelectedTab(selectedTabIndex),
    []
  );

  console.log("Patient Tags:", patient?.tags);

  return (
    <AppPageWrapper>
      <Page
        title={
          !isLoading
            ? `${patient?.firstname} ${patient?.lastname}`
            : t("patients.patient")
        }
        titleMetadata={
          <Badge tone="success-strong">{t("patients.status")}</Badge>
        }
        secondaryActions={
          <InlineStack gap={"200"}>
            <Button icon={PlusIcon}>
              {t("patients.actions.newTreatment")}
            </Button>

            <Button icon={PrintIcon}>{t("patients.actions.documents")}</Button>
            <Popover
              active={popoverActive}
              activator={
                <Button onClick={togglePopoverActive} disclosure>
                  {t("patients.actions.more")}
                </Button>
              }
              autofocusTarget="first-node"
              onClose={togglePopoverActive}
            >
              <ActionList
                actionRole="menuitem"
                sections={[
                  {
                    items: [
                      {
                        content: t("patients.actions.hidePaymentInformation"),
                        onAction: () => {},
                        icon: HideIcon,
                      },
                    ],
                  },
                  {
                    items: [
                      {
                        content: t("patients.actions.addImages"),
                        onAction: () => {},
                        icon: ImageIcon,
                      },
                      {
                        content: t("patients.actions.addSMSReminder"),
                        onAction: () => {},
                        icon: ChatIcon,
                      },
                      {
                        content: t("patients.actions.addTask"),
                        onAction: () => {},
                        icon: StatusActiveIcon,
                      },
                      {
                        content: t("patients.actions.addLink"),
                        onAction: () => {},
                        icon: LinkIcon,
                      },
                      {
                        content: t("patients.actions.printPatientFile"),
                        onAction: () => {},
                        icon: PrintIcon,
                      },
                    ],
                  },
                  {
                    items: [
                      {
                        content: t("patients.actions.changePatientFields"),
                        onAction: () => {},
                        icon: EditIcon,
                      },
                    ],
                  },
                  {
                    title: t("patients.actions.NHIS"),
                    items: [
                      {
                        content: t("patients.actions.viewPatientFile"),
                        onAction: () => {},
                        icon: ViewIcon,
                      },
                      {
                        content: t("patients.actions.newPrescription"),
                        onAction: () => {},
                        icon: NoteIcon,
                      },
                      {
                        content: t("patients.actions.addMedicalNote"),
                        onAction: () => {},
                        icon: NoteIcon,
                      },
                    ],
                  },
                ]}
              />
            </Popover>
          </InlineStack>
        }
        pagination={{
          hasPrevious: true,
          hasNext: true,
        }}
        backAction={{
          content: t("patients.title"),
          url: `${ROOTS.CLINIC}/${clinicId}/${CLINIC_ROUTES.PATIENTS}`,
        }}
      >
        <Layout>
          <Layout.Section>
            <BlockStack gap={"400"}>
              <Card>
                <InlineStack align="space-between">
                  <BlockStack gap={"200"}>
                    <Text as="p">{t("patients.lastVisit")}</Text>
                    {patient?.lastVisit ? (
                      <Box>
                        <RenderDate
                          date={patient.lastVisit}
                          variant="headingSm"
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                  </BlockStack>
                  <BlockStack gap={"200"} inlineAlign="center">
                    <Text as="p">{t("patients.totalVisits")}</Text>
                    <Text as="p" variant="headingSm">
                      {patient?.totalVisits ?? 5}
                    </Text>
                  </BlockStack>
                  <BlockStack gap={"200"}>
                    <Text as="p">{t("patients.paid")}</Text>
                    <Text as="p" variant="headingSm">
                      {patient?.paid ?? "3,400.00 BGN"}
                    </Text>
                  </BlockStack>
                </InlineStack>
              </Card>
              <BlockStack>
                <div className="details-filters">
                  <Filters
                    queryValue={""}
                    filters={[]}
                    appliedFilters={[]}
                    onQueryChange={() => {}}
                    onQueryClear={() => {}}
                    onClearAll={() => {}}
                  />
                </div>
                <Tabs
                  tabs={tabs}
                  selected={selectedTab}
                  onSelect={handleTabChange}
                ></Tabs>
              </BlockStack>
              <Card padding={"0"}>
                <Box padding={"400"}>
                  <Text as="h1" variant="headingMd">
                    {t("patients.upcoming")}
                  </Text>
                </Box>
                <ResourceList
                  resourceName={{ singular: "customer", plural: "customers" }}
                  items={[
                    {
                      id: "110",
                      title: t("patients.tasks.taskTitle"),
                      description: t("patients.tasks.taskDescription"),
                      date: formatDate("2024-04-30", i18n.language),
                      icon: CalendarTimeIcon,
                    },
                    {
                      id: "111",
                      title: t("patients.tasks.smsReminder"),
                      description: t("patients.tasks.smsDescription"),
                      date: formatDate("2024-04-30", i18n.language),
                      icon: EmailIcon,
                    },
                    {
                      id: "112",
                      title: t("patients.tasks.treatment"),
                      badge: t("patients.status"),
                      description: t("patients.tasks.treatmentDescription"),
                      date: "",
                      icon: PlusCircleIcon,
                    },
                  ]}
                  renderItem={(item) => {
                    const { id, title, description, date, badge, icon } = item;
                    const media = <Icon source={icon} />;

                    return (
                      <ResourceItem
                        onClick={() => {}}
                        id={id}
                        media={media}
                        accessibilityLabel={`View details for ${title}`}
                      >
                        <InlineStack
                          align="space-between"
                          wrap={false}
                          blockAlign="center"
                        >
                          <InlineStack align="space-between" gap={"100"}>
                            <BlockStack gap={"100"}>
                              <InlineStack align="start" gap={"200"}>
                                <Text
                                  variant="bodyMd"
                                  fontWeight="bold"
                                  as="h3"
                                >
                                  {title}
                                </Text>

                                {badge && (
                                  <Box>
                                    <Badge tone="success-strong">{badge}</Badge>
                                  </Box>
                                )}
                              </InlineStack>
                              <Box maxWidth="400px">
                                <Text as="p" tone="subdued">
                                  {description}
                                </Text>
                              </Box>
                            </BlockStack>
                            <Text as="p">{date}</Text>
                          </InlineStack>
                          <Box>
                            <Icon source={ChevronRightIcon} />
                          </Box>
                        </InlineStack>
                      </ResourceItem>
                    );
                  }}
                />
              </Card>
              <Card padding={"0"}>
                <Box padding={"400"}>
                  <Text as="h1" variant="headingMd">
                    {formatDate("2024-05-30", i18n.language)}
                  </Text>
                </Box>
                <Box padding={"400"}>
                  <BlockStack gap={"300"}>
                    <Text as="h1" variant="headingSm">
                      {t("patients.attachedImages")}
                    </Text>
                    <InlineStack gap={"100"} align="space-evenly">
                      <SkeletonThumbnail size="large" />
                      <SkeletonThumbnail size="large" />
                      <SkeletonThumbnail size="large" />
                      <SkeletonThumbnail size="large" />
                      <SkeletonThumbnail size="large" />
                      <Box
                        borderColor="border-secondary"
                        borderWidth="100"
                        borderRadius="400"
                        minWidth="80px"
                        minHeight="80px"
                        padding={"600"}
                      >
                        <InlineStack align="center">
                          <BlockStack align="center">
                            <Box paddingBlockStart={"100"}>
                              <Text as="h1" variant="bodyLg">
                                +10
                              </Text>
                            </Box>
                          </BlockStack>
                        </InlineStack>
                      </Box>
                    </InlineStack>
                  </BlockStack>
                </Box>
                <Divider />
                <ResourceList
                  resourceName={{ singular: "customer", plural: "customers" }}
                  items={[
                    {
                      id: "112",
                      title: t("patients.tasks.treatment"),
                      description: t("patients.tasks.treatmentDescription"),
                      date: formatDate("2024-05-12", i18n.language),
                      badge: true,
                      icon: ContractFilledIcon,
                    },
                    {
                      id: "113",
                      title: t("patients.tasks.informedConsent"),
                      description: t("patients.tasks.consentDescription"),
                      date: formatDate("2024-05-12", i18n.language),
                      icon: NoteIcon,
                    },
                  ]}
                  renderItem={(item) => {
                    const { id, title, description, date, badge, icon } = item;
                    const media = <Icon source={icon} />;

                    return (
                      <ResourceItem
                        onClick={() => {}}
                        id={id}
                        media={media}
                        accessibilityLabel={`View details for ${title}`}
                      >
                        <InlineStack
                          align="space-between"
                          wrap={false}
                          blockAlign="center"
                        >
                          <InlineStack align="space-between" gap={"100"}>
                            <BlockStack gap={"100"}>
                              <Text variant="bodyMd" fontWeight="bold" as="h3">
                                {title}
                              </Text>
                              <Box width="300px">
                                <Text as="p" tone="subdued">
                                  {description}
                                </Text>
                              </Box>
                              {badge && (
                                <InlineStack gap={"200"} align="start">
                                  <Badge>{t("patients.tasks.implants")}</Badge>
                                  <Badge>
                                    {t("patients.tasks.anotherTag")}
                                  </Badge>
                                </InlineStack>
                              )}
                            </BlockStack>
                            <Text as="p">{date}</Text>
                          </InlineStack>
                          <Box>
                            <Icon source={ChevronRightIcon} />
                          </Box>
                        </InlineStack>
                      </ResourceItem>
                    );
                  }}
                />
              </Card>
            </BlockStack>
            <br />
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <BlockStack gap={"400"}>
              <Card>
                <InlineStack align="space-between">
                  <Box paddingBlockEnd={"200"}>
                    <Text as="h1" variant="headingMd">
                      {t("patients.notes")}
                    </Text>
                  </Box>
                  <Box>
                    <Icon source={EditIcon} />
                  </Box>
                </InlineStack>
                <p>Note</p>
              </Card>
              <Card>
                <Box paddingBlockEnd={"200"}>
                  <Text as="h1" variant="headingMd">
                    {t("patients.assignedDoctor")}
                  </Text>
                </Box>
                <p>Dimitor Kosturkov</p>
              </Card>
              <Card>
                <Box paddingBlockEnd={"200"}>
                  <Text as="h1" variant="headingMd">
                    {t("patients.patient")}
                  </Text>
                </Box>
                <BlockStack gap={"300"}>
                  <BlockStack gap={"0"}>
                    <Text as="p">{`${patient?.firstname || ""} ${
                      patient?.lastname || ""
                    }`}</Text>
                    <Text as="p">{t("patients.ucnNumber")}</Text>
                  </BlockStack>
                  <BlockStack>
                    <Box paddingBlockEnd={"200"}>
                      <Text as="h1" variant="headingSm">
                        {t("patients.contacts")}
                      </Text>
                    </Box>
                    <InlineStack align="space-between">
                      <Link url={`mailto:${patient?.email}`}>
                        {patient?.email}
                      </Link>
                      <Button
                        variant="plain"
                        icon={ClipboardIcon}
                        onClick={() => copyToClipboard(patient?.email ?? "")}
                      />
                    </InlineStack>
                    <InlineStack align="space-between">
                      <Link
                        url={`tel:${patient?.phoneCountryCode}${patient?.phone}`}
                      >
                        {`${patient?.phoneCountryCode} ${patient?.phone}`}
                      </Link>
                      <Button
                        variant="plain"
                        icon={ClipboardIcon}
                        onClick={() =>
                          copyToClipboard(
                            `${patient?.phoneCountryCode} ${patient?.phone}`
                          )
                        }
                      />
                    </InlineStack>
                  </BlockStack>
                  <BlockStack>
                    <Box paddingBlockEnd={"200"}>
                      <Text as="h1" variant="headingSm">
                        {t("patients.address")}
                      </Text>
                    </Box>
                    <Text as="p">Sofia</Text>
                    <Text as="p">Bulgaria</Text>
                  </BlockStack>

                  <BlockStack>
                    <Box paddingBlockEnd={"200"}>
                      <Text as="h1" variant="headingSm">
                        {t("patients.smoker")}
                      </Text>
                    </Box>
                    <InlineStack align="space-between">
                      <Text as="p">{t("patients.smokerStatus")}</Text>
                      <p>{t("patients.nonSmoker")}</p>
                    </InlineStack>
                  </BlockStack>

                  <BlockStack>
                    <Box paddingBlockEnd={"200"}>
                      <Text as="h1" variant="headingSm">
                        {t("patients.insurance")}
                      </Text>
                    </Box>
                    <InlineStack align="space-between">
                      <Text as="p">
                        {t("patients.personalInsuranceNumber")}
                      </Text>
                      <p>12390</p>
                    </InlineStack>
                    <InlineStack align="space-between">
                      <Text as="p">{t("patients.healthRegion")}</Text>
                      <p>Bonsko</p>
                    </InlineStack>
                    <InlineStack align="space-between">
                      <Text as="p">{t("patients.livingConditions")}</Text>
                      <p>Unfavorable</p>
                    </InlineStack>
                  </BlockStack>
                </BlockStack>
              </Card>

              <Card>
                <Box paddingBlockEnd={"200"}>
                  <Text as="h1" variant="headingMd">
                    {t("patients.tagsTitle")}
                  </Text>
                </Box>
                <TagsList
                  selectedTags={patient?.tags || []}
                  onTagsUpdate={handleTagsUpdate}
                />
              </Card>
            </BlockStack>
          </Layout.Section>
        </Layout>
      </Page>
    </AppPageWrapper>
  );
}

export default PatientDetails;
