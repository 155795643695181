/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect, useRef } from "react";
import {
  BlockStack,
  InlineStack,
  Modal,
  Checkbox,
  Button,
  Badge,
  Card,
  Box,
  Divider,
} from "@shopify/polaris";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { Property } from "../../types";
import { useTranslation } from "react-i18next";
import TranslateLink from "@/common/TranslateLink";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DragHandleIcon } from "@shopify/polaris-icons";
import type { XYCoord } from "react-dnd";

interface CreateFormModalProps {
  open: boolean;
  onClose: () => void;
  properties: Property[];
  clinicId: string;
}

interface DragItem {
  index: number;
}

const ItemType = "PROPERTY";

const DraggableItem: React.FC<{
  property: Property;
  index: number;
  moveProperty: (fromIndex: number, toIndex: number) => void;
  selectedFields: string[];
  setSelectedFields: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ property, index, moveProperty, selectedFields, setSelectedFields }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: string | symbol | null }
  >({
    accept: ItemType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveProperty(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  const opacity = isDragging ? 0.4 : 1;
  const cursor = isDragging ? "grabbing" : "grab";

  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <Box padding="300">
        <InlineStack gap="200" blockAlign="center">
          <div style={{ cursor }}>
            <Button icon={DragHandleIcon} variant="plain" />
          </div>
          <BlockStack gap="200">
            <Checkbox
              label={property.label}
              checked={selectedFields.includes(property.name)}
              onChange={(checked) => {
                const updatedFields = checked
                  ? [...selectedFields, property.name]
                  : selectedFields.filter((name) => name !== property.name);
                setSelectedFields(updatedFields);
              }}
            />
            <InlineStack gap="200">
              {property.isRequired && (
                <Badge>{t("createFormModal.mandatory")}</Badge>
              )}
              {property.isShortlisted && (
                <Badge>{t("createFormModal.shortlisted")}</Badge>
              )}
            </InlineStack>
          </BlockStack>
        </InlineStack>
      </Box>
      <Divider />
    </div>
  );
};

const CreateFormModal: React.FC<CreateFormModalProps> = ({
  open,
  onClose,
  properties,
  clinicId,
}) => {
  const { PATCH } = useApiRequest();
  const queryClient = useQueryClient();
  const [selectedFields, setSelectedFields] = useState<string[]>(
    properties
      .filter((property) => property.isInCreateForm)
      .map((property) => property.name)
  );
  const [orderedProperties, setOrderedProperties] = useState<Property[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const initialProperties = properties
      .filter(
        (property) => !property.isHiddenDefinition && !property.isReadOnlyValue
      )
      .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));
    setOrderedProperties(initialProperties);
  }, [properties]);

  const mutation = useMutation({
    mutationFn: (updatedProperties: any) =>
      PATCH(
        `/api/healthcare-companies/${clinicId}/patients/schema/properties/reorder-with-attributes`,
        {
          properties: updatedProperties,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["patient-schema"] });
      queryClient.invalidateQueries({
        queryKey: ["patient-schema-properties"],
      });
      onClose();
    },
    onError: (error) => {
      console.error("Failed to update properties:", error);
    },
  });

  const handleSave = () => {
    const updatedProperties = orderedProperties.map((property, index) => ({
      name: property.name,
      displayOrder: index + 1,
      isInCreateForm: selectedFields.includes(property.name),
    }));

    mutation.mutate(updatedProperties);
  };

  const moveProperty = (fromIndex: number, toIndex: number) => {
    const updatedProperties = [...orderedProperties];
    const [movedItem] = updatedProperties.splice(fromIndex, 1);
    updatedProperties.splice(toIndex, 0, movedItem);
    setOrderedProperties(updatedProperties);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Modal
        open={open}
        onClose={onClose}
        title={t("createFormModal.title")}
        primaryAction={{
          content: t("createFormModal.saveButton"),
          onAction: handleSave,
          loading: mutation.isPending,
        }}
        secondaryActions={[
          {
            content: t("createFormModal.cancelButton"),
            onAction: onClose,
          },
        ]}
      >
        <Modal.Section>
          <BlockStack gap="400">
            <TranslateLink text={t("createFormModal.description")} />
            <BlockStack gap="200">
              <Card padding="0">
                {orderedProperties.map((property, index) => (
                  <DraggableItem
                    key={property.name}
                    property={property}
                    index={index}
                    moveProperty={moveProperty}
                    selectedFields={selectedFields}
                    setSelectedFields={setSelectedFields}
                  />
                ))}
              </Card>
            </BlockStack>
          </BlockStack>
        </Modal.Section>
      </Modal>
    </DndProvider>
  );
};

export default CreateFormModal;
