import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Listbox, Combobox, Icon, BlockStack, Text } from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";

interface ComboOption {
  label: string;
  value: string;
  displayOrder?: number;
}

interface SingleSelectComboboxProps {
  options: ComboOption[];
  value: string;
  onChange: (value: string) => void;
  label: string;
  disabled?: boolean;
  helpText?: string;
  error?: string;
}

const SingleSelectCombobox: React.FC<SingleSelectComboboxProps> = ({
  options,
  value,
  onChange,
  label,
  disabled = false,
  helpText,
  error,
}) => {
  const { t } = useTranslation();
  const sortedOptions = useMemo(
    () =>
      [...options].sort(
        (a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0)
      ),
    [options]
  );

  const [inputValue, setInputValue] = useState("");

  const [filteredOptions, setFilteredOptions] = useState<ComboOption[]>([]);

  useEffect(() => {
    const initialOptions = sortedOptions.slice(0, 10);
    setFilteredOptions(initialOptions);
  }, [sortedOptions, value]);

  useEffect(() => {
    const selectedOption = sortedOptions.find(
      (option) => option.value === value
    );
    setInputValue(selectedOption ? selectedOption.label : "");
  }, [sortedOptions, value]);

  const escapeSpecialRegExCharacters = useCallback(
    (value: string) => value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
    []
  );

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value === "") {
        setFilteredOptions(sortedOptions.slice(0, 10));
        return;
      }

      const filterRegex = new RegExp(escapeSpecialRegExCharacters(value), "i");
      const resultOptions = sortedOptions.filter((option) =>
        option.label.match(filterRegex)
      );

      // Ensure the selected option is always included
      const selectedOption = sortedOptions.find(
        (option) => option.value === value
      );
      if (selectedOption && !resultOptions.includes(selectedOption)) {
        resultOptions.push(selectedOption);
      }

      setFilteredOptions(resultOptions);
    },
    [sortedOptions, escapeSpecialRegExCharacters]
  );

  const updateSelection = useCallback(
    (selected: string) => {
      const matchedOption = sortedOptions.find(
        (option) => option.value === selected
      );
      onChange(selected);
      setInputValue((matchedOption && matchedOption.label) || "");
    },
    [sortedOptions, onChange]
  );

  const optionsMarkup =
    filteredOptions.length > 0
      ? filteredOptions.map((option) => {
          const { label, value } = option;
          return (
            <Listbox.Option
              key={value}
              value={value}
              selected={value === inputValue}
              accessibilityLabel={label}
            >
              {label}
            </Listbox.Option>
          );
        })
      : null;

  return (
    <BlockStack gap="100">
      <Text variant="bodyMd" as="span">
        {label}
      </Text>
      <Combobox
        activator={
          <Combobox.TextField
            prefix={<Icon source={SearchIcon} />}
            onChange={updateText}
            label={label}
            labelHidden
            value={inputValue}
            placeholder={t("common.search")}
            autoComplete="off"
            disabled={disabled}
            helpText={helpText}
            error={error}
          />
        }
      >
        {filteredOptions.length > 0 ? (
          <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
        ) : null}
      </Combobox>
    </BlockStack>
  );
};

export default SingleSelectCombobox;
