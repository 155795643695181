import React from "react";
import { clearBrowserCache } from "@/utils/clearCache";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  async componentDidCatch(error: Error): Promise<void> {
    if (error.message.includes("Unexpected token '<'")) {
      console.log(
        "Detected asset loading error, clearing cache and reloading..."
      );

      try {
        await clearBrowserCache();
        // Add a timestamp to force a fresh reload
        window.location.href =
          window.location.href +
          (window.location.href.includes("?") ? "&" : "?") +
          "_t=" +
          Date.now();
      } catch (e) {
        // If something goes wrong with cache clearing, do a simple reload
        window.location.reload();
      }
    }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            fontFamily: "system-ui, -apple-system, sans-serif",
          }}
        >
          <p></p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
