import AddClinic from "./clinic/pages/clinics/components/AddClinic";
import AppModeSelector from "@/common/AppModeSelector";
import CheckAuthApp from "@/auth/components/CheckAuthApp";
import ClinicList from "@/clinic/pages/clinics/components/ClinicList";
import ClinicWrapper from "@/clinic/components/ClinicWrapper";
import DevExpressScheduler from "@/common/DevExpressScheduler";
import NotYetPlannedPage from "@/common/NotYetPlannedPage";
import LoadingPage from "@/common/LoadingPage";
import Login from "@/auth/components/Login";
import Logout from "./auth/components/Logout";
import NotFoundPage from "@/common/NotFoundPage";
import OttExchange from "@/auth/components/OttExchange";
import PasswordReset from "@/auth/components/PasswordReset";
import PatientDashboard from "@/patient/pages/dashboard";
import PatientDetails from "@/clinic/pages/patient/components/PatientDetail";
import PatientList from "@/clinic/pages/patient/components/PatientList";
import Perks from "@/clinic/pages/perks/components/Perks";
import ProtectedRoute from "@/common/ProtectedRoute";
import Register from "@/auth/components/Register";
import SecurityPage from "@/user/pages/security/components/SecurityPage";
import TwoFactor from "@/auth/components/TwoFactor";
import useCheckToken from "@/hooks/useCheckToken";
import useInvalidateQueriesOnLanguageChange from "@/hooks/useInvalidateQueriesOnLanguageChange";
import UserProfile from "@/user/pages/profile/components/UserProfile";
import UserWrapper from "@/user/components/UserWrapper";
import VerifyAlternativeEmail from "./user/pages/security/components/VerifyAlternativeEmail";
import {
  CLINIC_ROUTES,
  ROOTS,
  ROUTES,
  SETTINGS_ROUTES,
  USER_ROUTES,
} from "@/utils/Constants";
import { lazy, Suspense } from "react";
import { RootState } from "@/store";
import { Outlet, Route, Routes } from "react-router-dom";
import { useAppLoadGTM } from "@/hooks/useGTM";
import { useInitializeApp } from "@/hooks/useInitializeApp";
import { useSelector } from "react-redux";
import "@/App.scss";
import SettingsLocations from "./clinic/pages/settings/components/SettingsLocations";
import SettingsSubsriptionPlan from "./clinic/pages/settings/components/SettingsSubsriptionPlan";
import SettingsMembersAndPermissions from "./clinic/pages/settings/components/SettingsMembersAndPermissions";
import SettingsInvoices from "./clinic/pages/settings/components/SettingsInvoices";
import CompanyDetails from "./clinic/pages/settings/components/CompanyDetails";
import EditMember from "./clinic/pages/settings/components/EditMember";
import EditInvitee from "./clinic/pages/settings/components/EditInvite";
import InvitationsCard from "./auth/components/InvitationsCard/InvitationsCard";
import JoinOrganizationsCard from "./auth/components/JoinOrganizationsCard/JoinOrganizarionsCard";
import JoinForm from "./auth/components/JoinForm/JoinForm";
import TeamsAccess from "./user/pages/teamsAccess/components/TeamsAccess";
import useCheckInviteToken from "./hooks/useCheckInviteToken";
import UserCompliancePage from "./user/pages/compliance/components/UserCompliancePage";
import CompliancePage from "./clinic/pages/settings/components/SettingsCompliance/CompliancePage";
import PatientProperties from "./clinic/pages/patient/components/DataManagement/PatientProperties";
import { PWAInstallWrapper } from "@/common/PWAInstallWrapper";
import { TagsPage } from "@/clinic/pages/patient/components/Tags/TagsPage";

const Questionnaire = lazy(() => import("@/common/Questionnaires"));

const getAuthRoutes = () => {
  return (
    <>
      <Route path={ROUTES.REGISTER} element={<Register />} />
      <Route path={ROUTES.REGISTER_CHECK_EMAIL} element={<CheckAuthApp />} />
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.LOGOUT} element={<Logout />} />
      <Route path={ROUTES.OTT} element={<OttExchange />} />
      <Route path={ROUTES.PASSWORD_RESET} element={<PasswordReset />} />
      <Route path={ROUTES.TWO_FACTOR} element={<TwoFactor />} />
      <Route path={ROUTES.JOIN} element={<JoinForm />} />
      <Route
        path={ROUTES.ALTERNATIVE_EMAIL_VERIFY}
        element={<VerifyAlternativeEmail />}
      />
    </>
  );
};

const getClinicRoutes = () => {
  return (
    <>
      <Route
        path={`${ROOTS.CLINIC}`}
        element={
          <ProtectedRoute>
            <ClinicList />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROOTS.CLINIC}/add`}
        element={
          <ProtectedRoute>
            <AddClinic />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROOTS.CLINIC}/:clinicId/*`}
        element={
          <ProtectedRoute>
            <ClinicWrapper />
          </ProtectedRoute>
        }
      >
        {/* Dashboard page */}
        <Route path="" element={<NotYetPlannedPage />} />
        <Route path={CLINIC_ROUTES.PATIENTS} element={<PatientList />} />
        <Route path={CLINIC_ROUTES.PATIENTS_TAGS} element={<TagsPage />} />
        <Route
          path={CLINIC_ROUTES.PATIENTS_PROCEDURES}
          element={<NotYetPlannedPage />}
        />
        <Route
          path={CLINIC_ROUTES.PATIENTS_TREATMENT_PLANS}
          element={<NotYetPlannedPage />}
        />
        <Route
          path={CLINIC_ROUTES.PATIENTS_MEDICATIONS}
          element={<NotYetPlannedPage />}
        />
        <Route
          path={CLINIC_ROUTES.PATIENTS_DATA_MANAGEMENT}
          element={<PatientProperties />}
        />
        <Route
          path={CLINIC_ROUTES.PATIENT_DETAILS}
          element={<PatientDetails />}
        />
        <Route
          path={CLINIC_ROUTES.APPOINTMENTS}
          element={<DevExpressScheduler />}
        />
        <Route
          path={CLINIC_ROUTES.STATISTICS}
          element={<NotYetPlannedPage />}
        />
        <Route path={CLINIC_ROUTES.MINI_SITE} element={<NotYetPlannedPage />} />
        <Route path={CLINIC_ROUTES.PAYMENTS} element={<NotYetPlannedPage />} />
        <Route path={CLINIC_ROUTES.DOCUMENTS} element={<NotYetPlannedPage />} />
        <Route path={CLINIC_ROUTES.PERKS} element={<Perks />} />

        <Route
          path={`${CLINIC_ROUTES.SETTINGS}/${SETTINGS_ROUTES.DETAILS}`}
          element={<CompanyDetails />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </>
  );
};

const getSettingsRoutes = () => {
  return (
    <Route
      path={`${ROOTS.CLINIC}/:clinicId/${CLINIC_ROUTES.SETTINGS}/*`}
      element={
        <ProtectedRoute>
          <Outlet />
        </ProtectedRoute>
      }
    >
      <Route path={SETTINGS_ROUTES.DETAILS} element={<CompanyDetails />} />
      <Route path={SETTINGS_ROUTES.LOCATIONS} element={<SettingsLocations />} />
      <Route
        path={SETTINGS_ROUTES.SUBSCRIPTION_PLAN}
        element={<SettingsSubsriptionPlan />}
      />
      <Route
        path={`${SETTINGS_ROUTES.MEMBERS_PERMISSIONS}/members/:memberId`}
        element={<EditMember />}
      />
      <Route
        path={`${SETTINGS_ROUTES.MEMBERS_PERMISSIONS}/invites/:inviteId`}
        element={<EditInvitee />}
      />
      <Route
        path={SETTINGS_ROUTES.MEMBERS_PERMISSIONS}
        element={<SettingsMembersAndPermissions />}
      />
      <Route path={SETTINGS_ROUTES.COMPLIANCE} element={<CompliancePage />} />
      <Route path={SETTINGS_ROUTES.INVOICES} element={<SettingsInvoices />} />

      <Route path="*" element={<NotFoundPage />} />
    </Route>
  );
};

const getPatientRoutes = () => {
  return (
    <Route
      path={`${ROOTS.PATIENT}`}
      element={
        <ProtectedRoute>
          <PatientDashboard />
        </ProtectedRoute>
      }
    />
  );
};

const getUserRoutes = () => {
  return (
    <Route
      path={`${ROOTS.USER}/*`}
      element={
        <ProtectedRoute>
          <UserWrapper />
        </ProtectedRoute>
      }
    >
      <Route
        path={""}
        element={
          <ProtectedRoute>
            <NotYetPlannedPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={USER_ROUTES.APP_MODES}
        element={
          <ProtectedRoute>
            <AppModeSelector />
          </ProtectedRoute>
        }
      />
      <Route
        path={USER_ROUTES.QUESTIONNAIRE}
        element={
          <Suspense fallback={<LoadingPage />}>
            <ProtectedRoute>
              <Questionnaire />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path={USER_ROUTES.INVITES}
        element={
          <Suspense fallback={<LoadingPage />}>
            <ProtectedRoute>
              <InvitationsCard />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path={USER_ROUTES.AUTOJOIN}
        element={
          <Suspense fallback={<LoadingPage />}>
            <ProtectedRoute>
              <JoinOrganizationsCard />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path={USER_ROUTES.PROFILE}
        element={
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path={USER_ROUTES.CALENDER}
        element={
          <ProtectedRoute>
            <NotYetPlannedPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={USER_ROUTES.SECURITY}
        element={
          <ProtectedRoute>
            <SecurityPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={USER_ROUTES.COMPLIANCE}
        element={
          <ProtectedRoute>
            <UserCompliancePage />
          </ProtectedRoute>
        }
      />
      <Route
        path={USER_ROUTES.TEAMACCESS}
        element={
          <ProtectedRoute>
            <TeamsAccess />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  );
};

function App() {
  useCheckToken();
  useCheckInviteToken();
  useInvalidateQueriesOnLanguageChange();
  useAppLoadGTM();
  useInitializeApp();

  const isAppLoading = useSelector(
    (state: RootState) => state.app.isInitializingApp
  );

  if (isAppLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Routes>
        {getAuthRoutes()}
        {getClinicRoutes()}
        {getSettingsRoutes()}
        {getPatientRoutes()}
        {getUserRoutes()}
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <NotFoundPage />
            </ProtectedRoute>
          }
        />
      </Routes>
      <PWAInstallWrapper />
    </>
  );
}

export default App;
