// src/clinic/pages/patient/components/PatientCellRenderer.tsx
import React from "react";
import {
  Avatar,
  Box,
  Icon,
  InlineStack,
  Tooltip,
  Badge,
} from "@shopify/polaris";
import { NoteIcon } from "@shopify/polaris-icons";
import RenderDate from "@/common/RenderDate";
import { getToneOrUndefined } from "../utils";
import { Patient, PatientJsonBadge, Column } from "../types";
import { useTranslation } from "react-i18next";
import { Progress, Tone } from "@shopify/polaris/build/ts/src/components/Badge";
import { formatNumber } from "@/clinic/utils";
import i18n from "@/i18n";

interface PatientCellRendererProps {
  column: Column;
  patient: Patient;
}

const PatientCellRenderer: React.FC<PatientCellRendererProps> = ({
  column,
  patient,
}) => {
  const { t } = useTranslation();

  const cellValue = patient[column.name] ?? "";

  const renderCellContent = () => {
    if (column?.id === "date") {
      return <RenderDate date={String(cellValue)} />;
    }
    if (column?.id === "number" && !!cellValue) {
      return formatNumber(Number(cellValue), i18n.language);
    }

    if (column?.id === "jsonBadge") {
      const badgeProps = cellValue as unknown as PatientJsonBadge;
      return (
        <Tooltip content={badgeProps.tooltip}>
          <Badge
            progress={badgeProps.progress as Progress}
            tone={badgeProps.tone as Tone}
          >
            {badgeProps.label}
          </Badge>
        </Tooltip>
      );
    }

    if (column?.name === "core_avatar") {
      const avatarData = patient["core_avatar"];
      if (Array.isArray(avatarData) && avatarData.length > 0) {
        // Create a srcSet string from the avatarData
        const srcSet = avatarData
          .map(({ src, width }) => `${src} ${width}w`)
          .join(", ");
        const sizes = "(max-width: 200px) 100vw, 200px"; // Example sizes attribute

        return (
          <img
            src={avatarData[0].src}
            srcSet={srcSet}
            sizes={sizes}
            alt={patient["core_firstname"]}
            height={28}
            width={28}
            style={{
              height: "28px",
              width: "28px",
              objectFit: "cover",
              borderRadius: "6px",
            }}
            loading="lazy"
          />
        );
      } else {
        const initials = [
          patient["core_firstname"]?.charAt(0),
          patient["core_lastname"]?.charAt(0),
        ]
          .filter(Boolean)
          .join("");
        return <Avatar initials={initials} name={patient["core_firstname"]} />;
      }
    }

    if (column?.id === "bool") {
      return (
        <Badge progress={cellValue ? "complete" : "incomplete"}>
          {cellValue ? t("common.yes") : t("common.no")}
        </Badge>
      );
    }

    return String(cellValue);
  };

  return (
    <InlineStack gap={"200"} wrap={false} blockAlign="center">
      {renderCellContent()}
      {column?.name === "core_avatar" && patient["core_private_note"] && (
        <Box>
          <Tooltip content={patient["core_private_note"]}>
            <Icon
              source={NoteIcon}
              tone={getToneOrUndefined(patient["core_private_note_tone"])}
            />
          </Tooltip>
        </Box>
      )}
    </InlineStack>
  );
};

export default PatientCellRenderer;
