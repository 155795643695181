export async function clearBrowserCache(): Promise<void> {
  // Clear caches
  if ("caches" in window) {
    const cacheKeys = await caches.keys();
    await Promise.all(cacheKeys.map((key) => caches.delete(key)));
  }

  // Clear service worker
  if ("serviceWorker" in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    await Promise.all(
      registrations.map((registration) => registration.unregister())
    );
  }

  // Clear storage
  localStorage.clear();
  sessionStorage.clear();

  // Clear application cache if available
  if ("applicationCache" in window) {
    try {
      // @ts-expect-error applicationCache is deprecated but might still exist in older browsers
      window.applicationCache.abort();
    } catch (e) {
      // Ignore errors
    }
  }
}
