import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MessageAction {
  type: "external_url" | "internal_url";
  label: string;
  url: string;
}

interface TopMessage {
  id: string;
  title: string;
  description: string;
  action: MessageAction;
  secondaryAction?: MessageAction;
  tone: "info" | "warning" | "critical" | "success";
  dismissible?: boolean;
  hideCloseIcon?: boolean;
}

interface TopMessagesState {
  messages: TopMessage[];
  hasLoaded: boolean; // New flag to track initial load
}

const initialState: TopMessagesState = {
  messages: [],
  hasLoaded: false,
};

const topMessagesSlice = createSlice({
  name: "topMessages",
  initialState,
  reducers: {
    setMessages(state, action: PayloadAction<TopMessage[]>) {
      state.messages = action.payload;
      state.hasLoaded = true;
    },
    dismissMessage(state, action: PayloadAction<string>) {
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload
      );
    },
  },
});

export const { setMessages, dismissMessage } = topMessagesSlice.actions;
export default topMessagesSlice.reducer;

export const hideMessagesBar = ({
  topMessages,
}: {
  topMessages: TopMessagesState;
}): boolean => {
  return topMessages.messages?.length === 0 && topMessages.hasLoaded;
};
