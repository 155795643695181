import { useState, useCallback } from "react";
import {
  Page,
  IndexTable,
  IndexFilters,
  Badge,
  Button,
  Text,
  IndexFiltersMode,
  Card,
  Popover,
  ActionList,
  Loading,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { Tag } from "../../types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { useParams } from "react-router-dom";
import {
  PlusCircleIcon,
  MenuHorizontalIcon,
  DuplicateIcon,
  EditIcon,
  DeleteIcon,
} from "@shopify/polaris-icons";
import type { IndexFiltersProps } from "@shopify/polaris";
import { CreateTagModal } from "./CreateTagModal";
import AppPageWrapper from "@/common/AppPageWrapper";
import TabsTableSkeleton from "@/common/Skeletons/TabsTableSkeleton";
import { RenameTagModal } from "./RenameTagModal";

export function TagsPage() {
  const { t } = useTranslation();
  const { clinicId } = useParams();
  const { GET, POST, PUT, DELETE } = useApiRequest();
  const queryClient = useQueryClient();
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [activePopoverId, setActivePopoverId] = useState<string | null>(null);
  const [tagToRename, setTagToRename] = useState<Tag | null>(null);
  const [tagToEdit, setTagToEdit] = useState<Tag | null>(null);

  // Query for fetching tags
  const {
    data: tags = [],
    isLoading: isLoadingTags,
    isFetching: isFetchingTags,
  } = useQuery<Tag[]>({
    queryKey: ["patient-tags", clinicId],
    queryFn: () => GET(`/api/healthcare-companies/${clinicId}/patients/tags`),
  });

  // Mutations
  const addTagMutation = useMutation({
    mutationFn: (tagData: Omit<Tag, "id">) =>
      POST(`/api/healthcare-companies/${clinicId}/patients/tags`, tagData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["patient-tags", clinicId] });
      setIsAddingTag(false);
    },
  });

  const removeTagMutation = useMutation({
    mutationFn: (tagId: string) =>
      DELETE(`/api/healthcare-companies/${clinicId}/patients/tags/${tagId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["patient-tags", clinicId] });
    },
  });

  const duplicateTagMutation = useMutation({
    mutationFn: async (tagId: string) => {
      const tagToDuplicate = tags.find((tag) => tag.id === tagId);
      if (!tagToDuplicate) throw new Error("Tag not found");

      return POST(`/api/healthcare-companies/${clinicId}/patients/tags`, {
        name: `${tagToDuplicate.name} (copy)`,
        color: tagToDuplicate.color,
        isDynamic: tagToDuplicate.isDynamic,
        icon: tagToDuplicate.icon,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["patient-tags", clinicId] });
    },
  });

  const updateTagMutation = useMutation({
    mutationFn: ({
      tagId,
      tagData,
    }: {
      tagId: string;
      tagData: Omit<Tag, "id">;
    }) =>
      PUT(
        `/api/healthcare-companies/${clinicId}/patients/tags/${tagId}`,
        tagData
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["patient-tags", clinicId] });
      setTagToRename(null);
      setTagToEdit(null);
      setIsAddingTag(false);
    },
  });

  // Index Table setup
  const resourceName = {
    singular: t("tags.tag"),
    plural: t("tags.tags"),
  };

  const togglePopover = (id: string) => {
    setActivePopoverId(activePopoverId === id ? null : id);
  };

  const handleAction = (action: string, tagId: string) => {
    setActivePopoverId(null);
    const tag = tags.find((t) => t.id === tagId);
    if (!tag) return;

    switch (action) {
      case "duplicate":
        duplicateTagMutation.mutate(tagId);
        break;
      case "rename":
        setTagToRename(tag);
        break;
      case "delete":
        removeTagMutation.mutate(tagId);
        break;
    }
  };

  const handleRowClick = (tag: Tag) => {
    setTagToEdit(tag);
    setIsAddingTag(true);
  };

  const handleTagSubmit = (tagData: Omit<Tag, "id">) => {
    if (tagToEdit) {
      updateTagMutation.mutate({ tagId: tagToEdit.id, tagData });
    } else {
      addTagMutation.mutate(tagData);
    }
  };

  const handleModalClose = () => {
    setIsAddingTag(false);
    setTagToEdit(null);
  };

  const rowMarkup = tags
    .filter((tag) => tag.name.toLowerCase().includes(searchValue.toLowerCase()))
    .map((tag, index) => (
      <IndexTable.Row
        id={tag.id}
        key={tag.id}
        position={index}
        onClick={() => handleRowClick(tag)}
      >
        <IndexTable.Cell>
          <Text as="span">{tag.name}</Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Badge size="small">{String(tag.patientCount || 0)}</Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={(e) => e.stopPropagation()}
          >
            <Popover
              active={activePopoverId === tag.id}
              activator={
                <div onClick={(e) => e.stopPropagation()}>
                  <Button
                    onClick={() => togglePopover(tag.id)}
                    variant="plain"
                    icon={MenuHorizontalIcon}
                  />
                </div>
              }
              onClose={() => setActivePopoverId(null)}
              preferredAlignment="right"
              preferredPosition="below"
            >
              <ActionList
                items={[
                  {
                    content: t("tags.duplicate"),
                    onAction: () => handleAction("duplicate", tag.id),
                    icon: DuplicateIcon,
                  },
                  {
                    content: t("tags.rename"),
                    onAction: () => handleAction("rename", tag.id),
                    icon: EditIcon,
                  },
                  {
                    content: t("tags.delete"),
                    onAction: () => handleAction("delete", tag.id),
                    icon: DeleteIcon,
                    destructive: true,
                  },
                ]}
              />
            </Popover>
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    ));

  const handleFiltersQueryChange = useCallback(
    (value: string) => setSearchValue(value),
    []
  );

  const filters: IndexFiltersProps["filters"] = [];
  const appliedFilters: IndexFiltersProps["appliedFilters"] = [];

  return (
    <AppPageWrapper>
      <Page
        title={t("tags.title")}
        primaryAction={
          <Button onClick={() => setIsAddingTag(true)} icon={PlusCircleIcon}>
            {t("tags.createTag")}
          </Button>
        }
      >
        {isLoadingTags ? (
          <TabsTableSkeleton />
        ) : (
          <Card padding={"0"}>
            <IndexFilters
              queryValue={searchValue}
              queryPlaceholder={t("tags.searchPlaceholder")}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={() => setSearchValue("")}
              tabs={[]}
              selected={0}
              filters={filters}
              appliedFilters={appliedFilters}
              onClearAll={() => {}}
              mode={IndexFiltersMode.Filtering}
              setMode={() => {}}
            />

            <IndexTable
              resourceName={resourceName}
              itemCount={tags.length}
              selectedItemsCount={0}
              onSelectionChange={() => {}}
              hasMoreItems
              selectable={false}
              headings={[
                { title: t("tags.name") },
                { title: t("tags.usageCount") },
                { title: "", alignment: "end" },
              ]}
            >
              {rowMarkup}
            </IndexTable>
          </Card>
        )}
        {isFetchingTags ||
          removeTagMutation.isPending ||
          (duplicateTagMutation.isPending && <Loading />)}

        <CreateTagModal
          open={isAddingTag}
          onClose={handleModalClose}
          onAddTag={handleTagSubmit}
          isSubmitting={addTagMutation.isPending || updateTagMutation.isPending}
          editTag={tagToEdit}
        />

        <RenameTagModal
          open={!!tagToRename}
          onClose={() => setTagToRename(null)}
          tag={tagToRename}
          onRename={(tagData) =>
            tagToRename &&
            updateTagMutation.mutate({ tagId: tagToRename.id, tagData })
          }
          isSubmitting={updateTagMutation.isPending}
        />
      </Page>
    </AppPageWrapper>
  );
}
