import React, { useState, useMemo } from "react";
import { TextField, ChoiceList, BlockStack, Icon } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "@shopify/polaris-icons";

interface SearchableChoiceListProps {
  label: string;
  options: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  helpText?: string;
  error?: string;
}

const SearchableChoiceList: React.FC<SearchableChoiceListProps> = ({
  label,
  options,
  value,
  onChange,
  disabled = false,
  helpText,
  error,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const filteredOptions = useMemo(() => {
    return options
      .filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .slice(0, 10);
  }, [options, searchTerm]);

  return (
    <BlockStack gap="200">
      {options.length > 10 && (
        <TextField
          autoComplete="off"
          label={label}
          value={searchTerm}
          onChange={setSearchTerm}
          placeholder={t("common.search")}
          disabled={disabled}
          helpText={helpText}
          error={error}
          prefix={<Icon source={SearchIcon} />}
          clearButton
          onClearButtonClick={() => setSearchTerm("")}
        />
      )}
      <ChoiceList
        title={""}
        choices={filteredOptions}
        selected={[value]}
        onChange={(selected) => onChange(selected[0])}
        disabled={disabled}
      />
    </BlockStack>
  );
};

export default SearchableChoiceList;
