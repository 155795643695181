import {
  Box,
  Button,
  InlineStack,
  Banner,
  SkeletonBodyText,
  BlockStack,
  SkeletonDisplayText,
} from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ROOTS } from "@/utils/Constants";
import { TopMessage } from "./types";
import { dismissMessage, setMessages } from "./topMessagesSlice";
import { RootState } from "@/store";
import { useEffect } from "react";
import TranslateLink from "../TranslateLink";

export default function TopMessages() {
  const { GET } = useApiRequest();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clinicId } = useParams();

  const { messages, hasLoaded } = useSelector(
    (state: RootState) => state.topMessages
  );

  const {
    data: fetchedMessages = [],
    isLoading,
    isSuccess,
  } = useQuery<TopMessage[]>({
    queryKey: ["topMessages"],
    queryFn: () => GET(`/api/healthcare-companies/${clinicId}/top-messages`),
  });

  useEffect(() => {
    if (isSuccess && fetchedMessages.length && !hasLoaded) {
      dispatch(setMessages(fetchedMessages));
    }
  }, [isSuccess, fetchedMessages, dispatch, hasLoaded]);

  const handleDismiss = (id: string) => {
    dispatch(dismissMessage(id));
  };

  return (
    <>
      {isLoading && (
        <Box paddingBlockEnd="200">
          <Banner hideIcon>
            <BlockStack gap="200">
              <SkeletonDisplayText />
              <SkeletonBodyText lines={4} />
              <SkeletonDisplayText size="small" />
            </BlockStack>
          </Banner>
        </Box>
      )}

      {!isLoading &&
        messages.map((message) => (
          <Box paddingBlockEnd="200" key={message.id}>
            <Banner
              title={message.title}
              tone={message.tone}
              onDismiss={
                !message.hideCloseIcon
                  ? () => handleDismiss(message.id)
                  : undefined
              }
            >
              <BlockStack gap="400">
                <TranslateLink text={message.description} />

                <InlineStack gap="400">
                  {message.action && (
                    <Button
                      variant={
                        message.action.type === "external_url"
                          ? "primary"
                          : "secondary"
                      }
                      onClick={() =>
                        message.action.type === "external_url"
                          ? window.open(message.action.url, "_blank")
                          : navigate(
                              `${ROOTS.CLINIC}/${clinicId}${message.action.url}`
                            )
                      }
                    >
                      {message.action.label}
                    </Button>
                  )}
                  {message.secondaryAction && (
                    <Button
                      onClick={() =>
                        message.secondaryAction?.type === "internal_url"
                          ? navigate(
                              `${ROOTS.CLINIC}/${clinicId}${message.secondaryAction.url}`
                            )
                          : window.open(message.secondaryAction?.url, "_blank")
                      }
                    >
                      {message.secondaryAction.label}
                    </Button>
                  )}
                </InlineStack>
              </BlockStack>
            </Banner>
          </Box>
        ))}
    </>
  );
}
