import { FooterHelp } from "@shopify/polaris";
import TranslateLink from "./TranslateLink";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  CLINIC_ROUTES,
  ROOTS,
  SETTINGS_ROUTES,
  USER_ROUTES,
} from "../utils/Constants";

function PageFooter() {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;

  const getTranslationKey = () => {
    // Remove trailing slash if exists
    const path = pathname.replace(/\/$/, "");

    // Check clinic routes
    if (path.startsWith(ROOTS.CLINIC)) {
      const clinicPathWithId = path.replace(ROOTS.CLINIC + "/", "");
      const afterClinicId = clinicPathWithId.replace(/^[^/]+\//, "");

      if (afterClinicId.startsWith("patients/")) {
        if (afterClinicId === CLINIC_ROUTES.PATIENTS_DATA_MANAGEMENT)
          return "help_footer.patients.data_management";
        if (afterClinicId === CLINIC_ROUTES.PATIENTS_TAGS)
          return "help_footer.patients.tags";
        if (afterClinicId === CLINIC_ROUTES.PATIENTS_PROCEDURES)
          return "help_footer.patients.procedures";
        if (afterClinicId === CLINIC_ROUTES.PATIENTS_TREATMENT_PLANS)
          return "help_footer.patients.treatment_plans";
        if (afterClinicId === CLINIC_ROUTES.PATIENTS_MEDICATIONS)
          return "help_footer.patients.medications";
        if (afterClinicId.match(/^patients\/[\w-]+$/))
          return "help_footer.patients.details";
        return "help_footer.patients";
      }

      if (afterClinicId === CLINIC_ROUTES.PERKS) return "help_footer.perks";
    }

    // Check user routes
    if (path.startsWith(ROOTS.USER)) {
      const userPath = path.replace(ROOTS.USER + "/", "");

      if (userPath === USER_ROUTES.DETAILS) return "help_footer.user.details";
      if (userPath === USER_ROUTES.CALENDER) return "help_footer.user.calendar";
      if (userPath === USER_ROUTES.SECURITY) return "help_footer.user.security";
      if (userPath === USER_ROUTES.COMPLIANCE)
        return "help_footer.user.compliance";
      if (userPath === USER_ROUTES.TEAMACCESS)
        return "help_footer.user.team_access";
    }

    // Check settings routes
    if (path.includes("/settings/")) {
      const settingsPath = path.split("/settings/")?.[1];

      if (settingsPath === SETTINGS_ROUTES.DETAILS)
        return "help_footer.company.details";
      if (settingsPath === SETTINGS_ROUTES.SUBSCRIPTION_PLAN)
        return "help_footer.company.billing";
      if (settingsPath === SETTINGS_ROUTES.MEMBERS_PERMISSIONS)
        return "help_footer.company.members_and_permissions";
      if (settingsPath === SETTINGS_ROUTES.COMPLIANCE)
        return "help_footer.company.compliance";
      if (settingsPath === SETTINGS_ROUTES.INVOICES)
        return "help_footer.company.invoices";
    }

    return "help_footer.default";
  };

  return (
    <FooterHelp>
      <TranslateLink text={t(getTranslationKey())} external={true} />
    </FooterHelp>
  );
}

export default PageFooter;
