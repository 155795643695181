/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, IndexTable, Text, Button, InlineStack } from "@shopify/polaris";
import { HideIcon, ViewIcon } from "@shopify/polaris-icons";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useMemo, useCallback, useRef } from "react";
import PatientCellRenderer from "./PatientCellRenderer";
import { Column } from "../types";

interface EditColumnsProps {
  data: any[];
  columns?: Column[];
  onColumnsChange: (columns: Column[]) => void;
}

/**
 * Draggable + Droppable item interface for react-dnd
 */
interface DragItem {
  type: string;
  id: string; // column.name
  index: number; // index in the columns array
}

/**
 * SortableColumn with react-dnd
 */
function SortableColumn({
  column,
  index,
  data,
  moveColumn,
  changeVisibility,
  rowMarkup,
}: {
  column: Column;
  index: number;
  data: any[];
  moveColumn: (dragIndex: number, hoverIndex: number) => void;
  changeVisibility: (column: Column, selected: boolean) => void;
  rowMarkup: () => JSX.Element[];
}) {
  const ref = useRef<HTMLDivElement>(null);

  // Don't allow drag for core_avatar column
  const isAvatarColumn = column.name === "core_avatar";

  const [{ isDragging }, drag] = useDrag<
    DragItem,
    void,
    { isDragging: boolean }
  >(
    () => ({
      type: "COLUMN",
      item: { type: "COLUMN", id: column.name, index },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: !isAvatarColumn, // Prevent dragging for core_avatar
    }),
    [index, column.name, isAvatarColumn]
  );

  const [, drop] = useDrop<DragItem>(
    () => ({
      accept: "COLUMN",
      hover: (item, monitor) => {
        if (!ref.current) return;

        const dragIndex = item.index;
        const hoverIndex = index;

        // Prevent dropping to index 0 (avatar column position)
        if (hoverIndex === 0) return;
        if (dragIndex === hoverIndex) return;

        // Calculate x offsets
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleX =
          (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
        const clientOffset = monitor.getClientOffset();
        if (!clientOffset) return;

        const hoverClientX = clientOffset.x - hoverBoundingRect.left;

        // If dragging to the left and not past half of the hovered column, return
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return;
        // If dragging to the right and not past half of the hovered column, return
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return;

        moveColumn(dragIndex, hoverIndex);

        // Once we reorder, we must update the item's index so it doesn't cause flicker
        item.index = hoverIndex;
      },
    }),
    [index, moveColumn]
  );

  // Correctly combine the refs
  if (!isAvatarColumn) {
    drag(drop(ref));
  }

  const opacity = isDragging ? 0.4 : 1.0;

  return (
    <div
      ref={ref}
      className={`edit-column ${column.visible ? "" : "disabled"} ${
        isAvatarColumn ? "disabled" : ""
      }`}
      style={{ cursor: isAvatarColumn ? "default" : "move", opacity }}
    >
      <Box borderRadius="200" borderColor="border-disabled" borderWidth="025">
        <IndexTable
          itemCount={data.length}
          selectedItemsCount={0}
          onSelectionChange={() => {}}
          headings={[
            {
              id: column.label,
              title: (
                <div style={{ width: "100%" }}>
                  <Box width="100%">
                    <InlineStack
                      gap={"400"}
                      align="space-between"
                      wrap={false}
                      blockAlign="center"
                    >
                      <Text as="span" variant="bodySm" fontWeight="semibold">
                        {column.label}
                      </Text>
                      <Button
                        id={column.name}
                        size="micro"
                        variant="plain"
                        onClick={() => {
                          changeVisibility(column, !column.visible);
                        }}
                        icon={!column.visible ? HideIcon : ViewIcon}
                      />
                    </InlineStack>
                  </Box>
                </div>
              ),
            },
          ]}
          selectable={false}
        >
          {rowMarkup()}
        </IndexTable>
      </Box>
    </div>
  );
}

/**
 * EditColumns component
 */
function EditColumns({
  data,
  columns = [],
  onColumnsChange,
}: EditColumnsProps) {
  const moveColumn = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      // Prevent moving any column to index 0
      if (hoverIndex === 0) return;

      const updated = [...columns];
      const [removed] = updated.splice(dragIndex, 1);
      updated.splice(hoverIndex, 0, removed);
      onColumnsChange(updated);
    },
    [columns, onColumnsChange]
  );

  const changeVisibility = useCallback(
    (column: Column, selected: boolean): void => {
      const updatedColumns = columns.map((c) => ({
        ...c,
        visible: c.name === column.name ? selected : c.visible,
      }));
      onColumnsChange(updatedColumns);
    },
    [columns, onColumnsChange]
  );

  const rowMarkup = useCallback(
    (column: Column) =>
      data.map((p, idx) => (
        <IndexTable.Row id={p.id} key={p.id} position={idx}>
          <IndexTable.Cell>
            <PatientCellRenderer column={column} patient={p} />
          </IndexTable.Cell>
        </IndexTable.Row>
      )),
    [data]
  );

  // Ensure core_avatar is always first in the columns array
  const sortedColumns = useMemo(() => {
    const avatarColumnIndex = columns.findIndex(
      (col) => col.name === "core_avatar"
    );
    if (avatarColumnIndex > 0) {
      const newColumns = [...columns];
      const [avatarColumn] = newColumns.splice(avatarColumnIndex, 1);
      newColumns.unshift(avatarColumn);
      return newColumns;
    }
    return columns;
  }, [columns]);

  const columnsMarkup = useMemo(
    () =>
      sortedColumns.map((column, index) => (
        <SortableColumn
          key={column.name}
          column={column}
          index={index}
          data={data}
          moveColumn={moveColumn}
          changeVisibility={changeVisibility}
          rowMarkup={() => rowMarkup(column)}
        />
      )),
    [sortedColumns, data, moveColumn, changeVisibility, rowMarkup]
  );

  return (
    <Box padding={"400"}>
      {/*
        DndProvider must wrap the entire region containing draggable and droppable items.
        We provide the HTML5Backend for typical mouse-based drag and drop.
      */}
      <DndProvider backend={HTML5Backend}>
        <div style={{ overflowX: "auto", overflowY: "hidden" }}>
          <InlineStack gap={"200"} wrap={false}>
            {columnsMarkup}
          </InlineStack>
        </div>
      </DndProvider>
    </Box>
  );
}

export default EditColumns;
