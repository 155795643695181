import AppFrame from "@/common/AppFrame";
import React from "react";
import usePageType from "@/hooks/usePageType";
import { PageTypes } from "@/types/commonTypes";
import TopMessages from "./TopMessages/TopMessages";
import { BlockStack, Page } from "@shopify/polaris";
import { useQueryClient } from "@tanstack/react-query";
import { SudoUser, User } from "@/types/user";
import SudoBanner from "./SudoBanner";
import NotPlannedBanner from "./NotYetPlannedBanner";
import NearingDevelopmentBanner from "./NearingDevelopmentBanner";
import PageFooter from "./PageFooter";
import { useSelector } from "react-redux";
import { hideMessagesBar } from "./TopMessages/topMessagesSlice";

interface AppPageWrapperProps {
  children: React.ReactNode;
  showNotPlannedBanner?: boolean;
  showNearDevelopmentBanner?: boolean;
}

const AppPageWrapper: React.FC<AppPageWrapperProps> = ({
  showNotPlannedBanner = false,
  showNearDevelopmentBanner = false,
  children,
}) => {
  const pageType = usePageType();
  const queryClient = useQueryClient();
  const sudoUser = queryClient.getQueryData<SudoUser>(["sudo-user"]);
  const user = queryClient.getQueryData<User>(["user"]);
  const hideTopBar = useSelector(hideMessagesBar);
  return (
    <AppFrame type={pageType}>
      {!!sudoUser?.sudo ||
      (pageType === PageTypes.CLINIC && !hideTopBar) ||
      showNotPlannedBanner ||
      showNearDevelopmentBanner ? (
        <Page fullWidth>
          <BlockStack gap={"300"}>
            {!!sudoUser?.sudo && user && (
              <SudoBanner user={user} sudoUser={sudoUser} />
            )}
            {pageType === PageTypes.CLINIC && <TopMessages />}
            {showNotPlannedBanner && <NotPlannedBanner />}
            {showNearDevelopmentBanner && <NearingDevelopmentBanner />}
          </BlockStack>
        </Page>
      ) : (
        <></>
      )}
      {children}
      <PageFooter />
    </AppFrame>
  );
};

export default AppPageWrapper;
