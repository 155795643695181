import {
  BlockStack,
  InlineStack,
  Tag,
  Autocomplete,
  Icon,
} from "@shopify/polaris";
import { useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { SearchIcon } from "@shopify/polaris-icons";

interface Tag {
  id: string;
  name: string;
  color?: string; // e.g., "hsl(120, 70.625%, 75.3125%)"
}

interface TagsListProps {
  selectedTags: string[];
  onTagsUpdate: (selectedTagIds: string[]) => void;
}

const StyledTag = styled.div<{ $color?: string }>`
  .Polaris-Tag {
    background-color: ${(props) => {
      console.log("Tag Background Color:", props.$color);
      return props.$color ?? "#e4e5e7";
    }};
  }
`;

function TagsList({ selectedTags, onTagsUpdate }: TagsListProps) {
  const { GET } = useApiRequest();
  const { clinicId } = useParams<{
    patientId: string;
    clinicId: string;
  }>();

  // Fetch tags from API and ensure each tag has a color
  const { data: tags = [], isLoading } = useQuery<Tag[]>({
    queryKey: ["patient-tags", clinicId],
    queryFn: async () => {
      const fetchedTags = await GET<Tag[]>(
        `/api/healthcare-companies/${clinicId}/patients/tags`
      );
      return fetchedTags.map((tag) => ({
        ...tag,
        color: tag.color || "hsl(0, 0%, 80%)", // Assign default color if missing
      }));
    },
  });

  const [queryValue, setQueryValue] = useState("");

  const removeTag = useCallback(
    (tagToRemove: Tag) => () => {
      const updatedTags = selectedTags.filter((id) => id !== tagToRemove.id);
      onTagsUpdate(updatedTags);
    },
    [selectedTags, onTagsUpdate]
  );

  const handleQueryChange = useCallback(
    (newValue: string) => setQueryValue(newValue),
    []
  );
  const handleQueryClear = useCallback(() => setQueryValue(""), []);

  // Filter tags based on search query
  const filteredTags = tags.filter((tag) =>
    tag.name.toLowerCase().includes(queryValue.toLowerCase())
  );

  const tagMarkup = selectedTags.map((tagId) => {
    const tag = tags.find((t) => t.id === tagId);
    return (
      <StyledTag key={tagId} $color={tag?.color}>
        <Tag onRemove={removeTag(tag as Tag)}>{tag?.name || tagId}</Tag>
      </StyledTag>
    );
  });

  const handleTagSelect = useCallback(
    (_selected: string[]) => {
      if (_selected.length > 0) {
        const selectedTagId = _selected[0];
        if (!selectedTags.includes(selectedTagId)) {
          const updatedTags = [...selectedTags, selectedTagId];
          onTagsUpdate(updatedTags);
        }
        setQueryValue("");
      }
    },
    [selectedTags, onTagsUpdate]
  );

  return (
    <BlockStack gap={"200"}>
      <Autocomplete
        loading={isLoading}
        options={filteredTags.map((tag) => ({
          label: tag.name,
          value: tag.id,
        }))}
        selected={selectedTags}
        onSelect={handleTagSelect}
        textField={
          <Autocomplete.TextField
            prefix={<Icon source={SearchIcon} />}
            label=""
            onChange={handleQueryChange}
            value={queryValue}
            placeholder="Search tags"
            clearButton
            onClearButtonClick={handleQueryClear}
            autoComplete="off"
          />
        }
      />

      {/* Display selected tags */}
      <InlineStack gap={"200"}>{tagMarkup}</InlineStack>
    </BlockStack>
  );
}

export default TagsList;
