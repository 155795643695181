import useApiRequest from "./useApiRequest";
import { ErrorResponse } from "@/types/commonTypes";
import { Patient } from "@/clinic/pages/patient/types";
import { useQuery } from "@tanstack/react-query";

const usePatientDetails = (patientId: string, clinicId: string) => {
  const { GET } = useApiRequest();

  const fetchPatientDetails = async (): Promise<Patient> => {
    return GET<Patient>(
      `/api/healthcare-companies/${clinicId}/patients/${patientId}`
    );
  };

  const { data, isLoading, error } = useQuery<Patient, ErrorResponse>({
    queryKey: ["patientDetails", patientId],
    queryFn: fetchPatientDetails,
  });

  return {
    patient: data,
    isLoading,
    error,
  };
};

export default usePatientDetails;
